import React from 'react'
import TopBar from './TopBar'
import { useAADAccount } from '../utils/auth'
import { useTranslation } from 'react-i18next'
import { ENVIRONMENT } from '../utils/api'

type Props = {
    children: React.ReactNode
    title?: string
}

export function PageWrapper(props: Props) {
    const account = useAADAccount(0)

    const [t] = useTranslation('common')

    if (props.title) {
        document.title = props.title
    }

    return account ? (
        <React.Fragment>
            <TopBar
                buttons={[
                    {
                        text: t('topBar.explore'),
                        urn: '/explore',
                    },
                    { text: t('topBar.tags'), urn: '/tags' },
                ]}
                user={{
                    completeName: account.name || 'error',
                    email: account.name || 'error',
                }}
            />
            {ENVIRONMENT === 'development' && (
                <span className="dev-disclaimer">{t('devEnvMessage')}</span>
            )}
            <div id="wrapped-content">{props.children}</div>
            {ENVIRONMENT === 'development' && (
                <span className="dev-disclaimer">{t('devEnvMessage')}</span>
            )}
            <footer>{t('footer')}</footer>
        </React.Fragment>
    ) : (
        <p>Error.</p>
    )
}

export default PageWrapper
