import React from 'react'
import PageWrapper from '../components/PageWrapper'
import ABBTitle from '../components/ABBTitle'
import { useQuery } from '@apollo/client'
import { GET_USER, GetUserResponse } from '../utils/api'
import { LoadingIndicator } from '@abb/abb-common-ux-react'
import { useTranslation } from 'react-i18next'

export function Profile() {
    const { loading, error, data } = useQuery<GetUserResponse>(GET_USER, {
        notifyOnNetworkStatusChange: true,
    })

    let [t] = useTranslation(['common', 'profile'])

    return (
        <PageWrapper
            title={`${t('title', { ns: 'profile' })} - ${t('appName')}`}
        >
            <div id="profile-content">
                {loading && (
                    <LoadingIndicator
                        style={{ width: '100vw' }}
                        type={'bar'}
                        determinate={false}
                        color={'red'}
                        sizeClass={'large'}
                    />
                )}
                {error && <p>{error.message}</p>}
                {data?.user && (
                    <React.Fragment>
                        <div className="profile-header">
                            <ABBTitle
                                className="profile-title"
                                text={`${data.user.name} ${data.user.surname}`}
                            />

                            <p className="profile-email">{data.user.email}</p>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </PageWrapper>
    )
}

export default Profile
