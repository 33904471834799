import React from 'react'
import { useTranslation } from 'react-i18next'
import SearchBar from '../components/SearchBar'
import PageWrapper from '../components/PageWrapper'
import { useHistory } from 'react-router-dom'
import logo from '../assets/img/forma-new-logo.svg'

export function Home() {
    const [t] = useTranslation('common')
    const history = useHistory()

    const onSearch = (text: string) => {
        if (text.length > 0) {
            history.push(encodeURI(`/search/${encodeURIComponent(text)}`))
        }
    }

    return (
        <PageWrapper title={t('appName')}>
            <div id="home-content">
                <img className="logo" src={logo} alt={t('appName')} />
                <SearchBar className="home-search-bar" onSearch={onSearch} />
            </div>
        </PageWrapper>
    )
}

export default Home
