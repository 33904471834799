import { getStoredLocale } from './localstorage'
import React from 'react'
// @ts-ignore
import Flags from 'country-flag-icons/react/3x2'
import { WillLocale } from './types'
import { enUS, it } from 'date-fns/locale'
import { getI18n } from 'react-i18next'

export const locales: WillLocale[] = [
    {
        name: 'Italiano (IT)',
        code: 'it-IT',
        icon: <Flags.IT title="Italiano (IT)" />,
        dateFNSLocale: it,
    },
    {
        name: 'English (US)',
        code: 'en-US',
        icon: <Flags.US title="English (US)" />,
        dateFNSLocale: enUS,
    },
]

export const getCurrentLocale = (): WillLocale => {
    const storedLocale = getStoredLocale()
    return locales.filter(
        (locale) =>
            locale.code ===
            (storedLocale ? storedLocale.code : getI18n()?.language || 'en-US') // TODO: find a better solution
    )[0]
}

export const capitalizeFirstLetter = (
    [first, ...rest]: string[],
    locale = getStoredLocale()?.code
) => first.toLocaleUpperCase(locale) + rest.join('')
