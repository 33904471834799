import React from 'react'
import { Link } from 'react-router-dom'
import { Tag } from '../utils/types'

type Props = {
    tag: Tag
    className?: string
    end?: string
}

export function TagLink(props: Props) {
    return (
        <Link
            className={`tag-link ${props.className}`}
            to={encodeURI(`/search/%23${encodeURIComponent(props.tag.name)}`)}
        >
            #{props.tag.name}
            {props.end && <span className="tag-link-end">{props.end}</span>}
        </Link>
    )
}

export default TagLink
