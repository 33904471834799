import React from 'react'
import { useMsal } from '@azure/msal-react'
import { Redirect } from 'react-router-dom'
import { useAADAccount } from '../utils/auth'

export function Logout() {
    const { instance } = useMsal()
    const account = useAADAccount()

    if (account) {
        instance.logoutRedirect()
        return <p>Logging out...</p>
    } else {
        return <Redirect to="/" />
    }
}

export default Logout
