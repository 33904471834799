import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdPlaylistPlay } from 'react-icons/md'

type Props = {
    token: string
    preview: { url: string; overlay: React.ReactElement }
    title: string | React.ReactElement
    subtitle: string | React.ReactElement
    onClick?: () => void
    onLoad?: () => void
    className?: string
    showIcon?: boolean
}

export const ExploreBox = React.memo(function ExploreBoxImpl(props: Props) {
    const [t] = useTranslation('common')

    const [thumbURL, setThumbURL] = useState<string>(
        `${props.preview.url}?token=${props.token}&size=640;360`
    )

    return (
        <div
            title={typeof props.title === 'string' ? props.title : undefined}
            className={`explore-box ${props.className && props.className}`}
            onClick={props.onClick}
        >
            <div className="image-container">
                <img
                    onError={() =>
                        setThumbURL(
                            `https://via.placeholder.com/640x360?text=${t(
                                'unavailable'
                            )}`
                        )
                    }
                    onLoad={props.onLoad}
                    src={thumbURL}
                    alt={t('unavailable')}
                />
                {props.showIcon && <MdPlaylistPlay className="overlay" />}
            </div>

            <div className="explore-box-title-subtitle">
                <p className="explore-box-title">{props.title}</p>
                <span className="explore-box-subtitle">{props.subtitle}</span>
            </div>
        </div>
    )
})

export default ExploreBox
