import { WillLocale } from './types'

const APP_ID = 'will_'
const LOCALE_KEY = APP_ID + 'locale'

export const getStoredLocale = (): WillLocale | undefined => {
    let localeString = localStorage.getItem(LOCALE_KEY)
    return localeString ? JSON.parse(localeString) : undefined
}

export const storeLocale = (locale: WillLocale): void => {
    console.log(locale)
    localStorage.setItem(
        LOCALE_KEY,
        JSON.stringify({ code: locale.code, name: locale.name })
    )
}
