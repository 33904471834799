import React from 'react'
import './App.scss'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './pages/Home'
import Logout from './pages/Logout'
import CoursePage from './pages/CoursePage'
import LessonPage from './pages/LessonPage'
import { ScrollToTop } from './components/ScrollToTop'
import SearchResults from './pages/SearchResults'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsalAuthentication,
} from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { LoadingIndicator } from '@abb/abb-common-ux-react'
import ApolloWrapper from './components/ApolloWrapper'
import Tags from './pages/Tags'
import Profile from './pages/Profile'
import Explore from './pages/Explore'
import Bookmarks from './pages/Bookmarks'
import TestPage from './pages/TestPage'
import CertificatePage from './pages/CertificatePage'
import CertificatesPage from './pages/CertificatesPage'

function App() {
    useMsalAuthentication(InteractionType.Redirect)

    return (
        <Router>
            <React.Fragment>
                <ScrollToTop />
                <AuthenticatedTemplate>
                    <ApolloWrapper>
                        <Switch>
                            <Route path="/logout">
                                <Logout />
                            </Route>
                            <Route path="/courses/:courseID/test">
                                <TestPage />
                            </Route>
                            <Route path="/courses/:courseID/:lessonIndex(\d+)">
                                <LessonPage />
                            </Route>
                            <Route path="/courses/:courseID">
                                <CoursePage />
                            </Route>
                            <Route path="/search/:searchText">
                                <SearchResults />
                            </Route>
                            <Route path="/tags">
                                <Tags />
                            </Route>
                            <Route path="/profile">
                                <Profile />
                            </Route>
                            <Route path="/explore">
                                <Explore />
                            </Route>
                            <Route path="/bookmarks">
                                <Bookmarks />
                            </Route>
                            <Route path="/certificates" exact>
                                <CertificatesPage />
                            </Route>
                            <Route path="/certificates/:courseID">
                                <CertificatePage />
                            </Route>
                            <Route path="/">
                                <Home />
                            </Route>
                        </Switch>
                    </ApolloWrapper>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div className="unauth-container">
                        <LoadingIndicator
                            type={'radial'}
                            determinate={false}
                            color={'red'}
                            sizeClass={'large'}
                        />
                    </div>
                </UnauthenticatedTemplate>
            </React.Fragment>
        </Router>
    )
}

export default App
