import React, { useState } from 'react'
import { MdSearch } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

type Props = {
    onSearch?(text: string): void
    onChange?(text: string): void
    className?: string
    defaultValue?: string
}

export function SearchBar(props: Props) {
    const [t] = useTranslation('common')
    const [text, setText] = useState<string>(props.defaultValue || '')

    return (
        <div
            className={`search-bar-container${
                props.className ? ' ' + props.className : ''
            }`}
        >
            <input
                defaultValue={
                    props.defaultValue && decodeURIComponent(props.defaultValue)
                }
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        if (props.onSearch) {
                            props.onSearch(text)
                        } else {
                            console.log('onSearch not set')
                        }
                    }
                }}
                onChange={(event) => {
                    setText(event.target.value)
                    if (props.onChange) {
                        props.onChange(event.target.value)
                    }
                }}
                className="search-bar"
                type="text"
                placeholder={`${t('search')}...`}
            />
            <MdSearch
                onClick={() =>
                    props.onSearch
                        ? props.onSearch(text)
                        : console.log('onSearch not set')
                }
                className="search-bar-icon"
                size={30}
            />
        </div>
    )
}

export default SearchBar
