import React, { useEffect, useRef, useState } from 'react'
import { Course, isCourse, isLesson, Lesson } from '../utils/types'
import { VIDEOS_BASE_URI } from '../utils/api'
import ExploreBox from './ExploreBox'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ABBTitle from './ABBTitle'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { capitalizeFirstLetter } from '../utils/locale'

type Props = {
    token: string
    categoryTitle: string
    content: (Course | Lesson)[]
    onLoad?: () => void
}

// this component is a hack
export function ExploreRow(props: Props) {
    let [t] = useTranslation(['common', 'explore'])
    const history = useHistory()

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    })

    const rowRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const rightArrowRef = useRef<HTMLDivElement>(null)
    const leftArrowRef = useRef<HTMLDivElement>(null)

    const resizeTimer = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        const doResize = () => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            })
            console.log(dimensions)
        }

        const handleResize = () => {
            if (resizeTimer.current) {
                clearTimeout(resizeTimer.current)
            }
            resizeTimer.current = setTimeout(doResize, 300)
        }

        if (containerRef.current) {
            window.addEventListener('resize', handleResize)
        }
        onScroll()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [containerRef, dimensions])

    useEffect(() => {
        if (containerRef && rowRef) {
            setLoaded(true)
        }
    }, [containerRef, rowRef])

    useEffect(() => {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
        })
    }, [])

    const threshold = 60

    const onScroll = () => {
        if (rowRef.current && rightArrowRef.current && leftArrowRef.current) {
            const scrollLeft = rowRef.current.scrollLeft
            const scrollRight =
                rowRef.current.scrollWidth -
                rowRef.current.scrollLeft -
                rowRef.current.clientWidth

            if (
                scrollLeft < threshold &&
                !leftArrowRef.current.classList.contains('hidden')
            ) {
                leftArrowRef.current.classList.add('hidden')
            } else if (
                scrollLeft > threshold &&
                leftArrowRef.current.classList.contains('hidden')
            ) {
                leftArrowRef.current.classList.remove('hidden')
            }

            if (
                scrollRight < threshold &&
                !rightArrowRef.current.classList.contains('hidden')
            ) {
                rightArrowRef.current.classList.add('hidden')
            } else if (
                scrollRight > threshold &&
                rightArrowRef.current.classList.contains('hidden')
            ) {
                rightArrowRef.current.classList.remove('hidden')
            }
        }
    }

    let timer: any = null

    const scrollRight = (amount: number) => {
        if (timer) {
            clearInterval(timer)
            timer = null
        }
        if (rowRef.current && !timer) {
            const target = Math.min(
                rowRef.current.scrollLeft + amount,
                rowRef.current.scrollWidth - rowRef.current.clientWidth
            )
            timer = setInterval(() => {
                if (rowRef.current) {
                    if (!timer) {
                        console.error('NO TIMER')
                    }
                    if (rowRef.current.scrollLeft >= target) {
                        clearInterval(timer)
                        timer = null
                    }
                    rowRef.current.scrollLeft += amount / 80
                }
            }, 1)
        }
    }

    const scrollLeft = (amount: number) => {
        if (timer) {
            clearInterval(timer)
            timer = null
        }
        if (rowRef.current && !timer) {
            const target = Math.min(
                rowRef.current.scrollLeft - amount,
                rowRef.current.scrollWidth - rowRef.current.clientWidth
            )
            timer = setInterval(() => {
                if (!timer) {
                    console.error('NO TIMER')
                }
                if (rowRef.current) {
                    if (
                        rowRef.current.scrollLeft <= target ||
                        rowRef.current.scrollLeft <= 0
                    ) {
                        clearInterval(timer)
                        timer = null
                    }
                    rowRef.current.scrollLeft -= amount / 80
                }
            }, 1)
        }
    }

    const [loaded, setLoaded] = useState<boolean>(false)

    return (
        <div className="explore-row" ref={containerRef}>
            <ABBTitle type={'secondary'} text={props.categoryTitle} />
            <div
                className="explore-row-content"
                ref={rowRef}
                onScroll={onScroll}
            >
                {props.content.map((item) => {
                    if (
                        isCourse(item) &&
                        item.lessons &&
                        item.lessons.length > 0
                    ) {
                        return (
                            <ExploreBox
                                showIcon
                                token={props.token}
                                preview={{
                                    url: `${VIDEOS_BASE_URI}${item.lessons[0].video?.thumbnailURL}`,
                                    overlay: <p>ciao</p>,
                                }}
                                onClick={() =>
                                    history.push(`/courses/${item.id}`)
                                }
                                key={item.id + Math.random() * 100}
                                title={item.name}
                                subtitle={
                                    <p>{`${item.lessons.length} ${
                                        item.lessons.length === 1
                                            ? t('lesson')
                                            : t('lessons')
                                    }`}</p>
                                }
                            />
                        )
                    } else if (isLesson(item)) {
                        return (
                            <ExploreBox
                                token={props.token}
                                preview={{
                                    url: `${VIDEOS_BASE_URI}${item.video?.thumbnailURL}`,
                                    overlay: <p>ciao</p>,
                                }}
                                onClick={() =>
                                    history.push(
                                        `/courses/${item.parent.id}/${
                                            item.parent.lessons.findIndex(
                                                (l) => l.id === item.id
                                            ) + 1
                                        }`
                                    )
                                }
                                key={item.id + Math.random() * 100}
                                title={item.name}
                                subtitle={
                                    <p>{`${capitalizeFirstLetter(
                                        t('partOf')
                                    )}: ${item.parent.name}`}</p>
                                }
                            />
                        )
                    } else {
                        return undefined
                    }
                })}
            </div>
            <div
                style={{
                    right:
                        containerRef?.current && rowRef?.current
                            ? -(
                                  rowRef.current.clientWidth -
                                  containerRef.current.clientWidth
                              ) / 2
                            : 0,
                }}
                ref={rightArrowRef}
                className={`${
                    !loaded ? 'hidden' : ''
                } scroll-arrow-container scroll-right-arrow-container`}
            >
                <MdChevronRight
                    onClick={() =>
                        scrollRight(
                            rowRef?.current
                                ? rowRef?.current?.clientWidth * 0.9
                                : window.innerWidth * 0.6
                        )
                    }
                    className="scroll-right-arrow"
                />
            </div>
            <div
                style={{
                    left:
                        containerRef?.current && rowRef?.current
                            ? -(
                                  rowRef.current.clientWidth -
                                  containerRef.current.clientWidth
                              ) / 2
                            : 0,
                }}
                ref={leftArrowRef}
                className={`${
                    !loaded ? 'hidden' : ''
                } scroll-arrow-container scroll-left-arrow-container hidden`}
            >
                <MdChevronLeft
                    onClick={() =>
                        scrollLeft(
                            rowRef?.current
                                ? rowRef?.current?.clientWidth * 0.9
                                : window.innerWidth * 0.6
                        )
                    }
                    className="scroll-left-arrow"
                />
            </div>
        </div>
    )
}

export default ExploreRow
