import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdPlayArrow } from 'react-icons/all'

type Props = {
    style?: React.CSSProperties
    className?: string
    width?: string
    height?: string
    sourceURLs: string[]
    preview?: string
    autoPlay?: boolean
    controls?: boolean
    progressInterval?: number
    onProgress?: ({
        playedPercentage,
        playedSeconds,
    }: {
        playedPercentage: number
        playedSeconds: number
    }) => void
}

export function LessonVideoPlayer(props: Props) {
    const [t] = useTranslation('common')

    const { progressInterval, onProgress } = props

    const [showingPreview, setShowingPreview] = useState<boolean>(
        props.preview !== undefined
    )

    const lastVideoTime = useRef<number>(0)

    const videoRef = useCallback(
        (node) => {
            console.log(node)
            if (node) {
                const videoEl = node as HTMLVideoElement

                const onTimeUpdate = () => {
                    if (
                        videoEl.currentTime * 1000 - lastVideoTime.current >
                        (progressInterval || 10000)
                    ) {
                        if (onProgress) {
                            onProgress({
                                playedPercentage:
                                    videoEl.currentTime / videoEl.duration,
                                playedSeconds: videoEl.currentTime,
                            })
                        }
                        lastVideoTime.current = videoEl.currentTime * 1000
                    }
                }

                videoEl.removeEventListener('timeupdate', onTimeUpdate)
                videoEl.addEventListener('timeupdate', onTimeUpdate)
            }
        },
        [progressInterval, onProgress]
    )

    return (
        <div
            className={`lesson-video-player${
                props.className ? props.className : ''
            }`}
        >
            {showingPreview && (
                <div
                    className="preview-container"
                    onClick={() => {
                        setShowingPreview(false)
                    }}
                >
                    <MdPlayArrow className="play-icon" />
                    <img
                        style={{
                            ...props.style,
                            width: props.width,
                            height: props.height,
                        }}
                        className="preview"
                        alt={t('errors.thumbnailUnavailable')}
                        src={props.preview}
                    />
                </div>
            )}
            {!showingPreview && (
                <video
                    ref={videoRef}
                    controls={props.controls}
                    autoPlay={props.autoPlay}
                    style={{
                        ...props.style,
                        width: props.width,
                        height: props.height,
                    }}
                >
                    {props.sourceURLs.map((url) => (
                        <source key={url} src={url} />
                    ))}
                </video>
            )}
        </div>
    )
}

export default LessonVideoPlayer
