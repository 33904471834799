import React from 'react'
import { Link, useParams } from 'react-router-dom'
import PageWrapper from '../components/PageWrapper'
import ABBTitle from '../components/ABBTitle'
import { MdAccessTime, MdSchool } from 'react-icons/md'
import FavoriteButton from '../components/FavoriteButton'
import CourseLessonPreview from '../components/CourseLessonPreview'
import { MdCheckCircle } from 'react-icons/all'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter, getCurrentLocale } from '../utils/locale'
import { formattedDuration } from '../utils/date'
import { useQuery } from '@apollo/client'
import { GET_COURSE, GetCourseInput, GetCourseResponse } from '../utils/api'
import { LoadingIndicator } from '@abb/abb-common-ux-react'
import PageNotFound from '../components/errors/PageNotFound'
import TagLink from '../components/TagLink'
import { format, parseISO } from 'date-fns'

export function CoursePage() {
    let { courseID } = useParams<{ courseID: string }>()
    let [t] = useTranslation(['common', 'course'])
    const locale = getCurrentLocale()

    const { loading, error, data } = useQuery<
        GetCourseResponse,
        GetCourseInput
    >(GET_COURSE, {
        variables: { input: { id: courseID } },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    console.log(data?.course)
    console.log(locale)

    return (
        <PageWrapper
            title={`${
                (data && data.course && data.course.name) || t('pageNotFound')
            } - ${t('appName')}`}
        >
            {!loading && data?.course === null && !error ? (
                <PageNotFound />
            ) : error ? (
                <p>{error.message}</p>
            ) : (
                <div id="course-content">
                    {loading ? (
                        <LoadingIndicator
                            style={{ width: '100vw' }}
                            type={'bar'}
                            determinate={false}
                            color={'red'}
                            sizeClass={'large'}
                        />
                    ) : data && data.course ? (
                        <React.Fragment>
                            <div className="header">
                                <ABBTitle
                                    className="header-title"
                                    text={data.course.name}
                                />
                                <div className="header-info">
                                    <p>{`${data.course.lessons.length} ${
                                        data.course.lessons.length === 1
                                            ? t('lesson', { ns: 'common' })
                                            : t('lessons', { ns: 'common' })
                                    }`}</p>
                                    <div className="header-info-time">
                                        <MdAccessTime />
                                        <p>
                                            {capitalizeFirstLetter(
                                                t('timeRequired', {
                                                    ns: 'common',
                                                })
                                            )}
                                            :{' '}
                                            {data.course
                                                ? formattedDuration(
                                                      data.course.lessons.reduce(
                                                          (tot, l) =>
                                                              tot +
                                                              l.requiredTimeSeconds,
                                                          0
                                                      )
                                                  )
                                                : t('unavailable', {
                                                      ns: 'common',
                                                  })}
                                        </p>
                                    </div>
                                    <FavoriteButton
                                        key={data.course.id}
                                        contentID={data.course.id}
                                        contentType={'COURSE'}
                                        isFavorite={data.course.isFavorite}
                                        className="course-favorite-button"
                                    />
                                </div>
                                {data.course.description &&
                                    data.course.description.length > 1 && ( // TODO: remove this monstruosity
                                        <p className="description">
                                            {data.course.description}
                                        </p>
                                    )}
                                <div className="header-tags-container">
                                    <span>
                                        {capitalizeFirstLetter(
                                            t('tags', { ns: 'common' })
                                        )}
                                        :
                                    </span>
                                    <div className="header-tags">
                                        {data.course.tags.map((tag, index) => {
                                            let end = ''
                                            if (
                                                index <
                                                data.course.tags.length - 1
                                            ) {
                                                end = ', '
                                            }
                                            return (
                                                <TagLink
                                                    className="preview-box-tag"
                                                    tag={tag}
                                                    key={tag.id}
                                                    end={end}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="lessons">
                                {data.course.lessons.length === 0 && (
                                    <p>{t('noLessons', { ns: 'course' })}</p>
                                )}
                                {data.course.lessons.map((lesson, index) => {
                                    return (
                                        <React.Fragment
                                            key={lesson.id + '-par'}
                                        >
                                            <CourseLessonPreview
                                                courseID={data.course.id}
                                                lessonIndex={index + 1}
                                                lesson={lesson}
                                                key={lesson.id}
                                            />
                                            <div
                                                key={lesson.id + '-sep'}
                                                className={`lessons-separator ${
                                                    (index <
                                                        data.course.lessons
                                                            .length -
                                                            1 &&
                                                        lesson.finished) ||
                                                    (lesson.finished &&
                                                        index ===
                                                            data.course.lessons
                                                                .length -
                                                                1)
                                                        ? 'highlighted'
                                                        : ''
                                                }`}
                                            />
                                        </React.Fragment>
                                    )
                                })}
                                {data.course.lessons.length > 0 && (
                                    <div
                                        className={`lessons-final-circle ${
                                            data.course.certificate ||
                                            data.course.lessons[
                                                data.course.lessons.length - 1
                                            ]?.finished
                                                ? 'finished'
                                                : ''
                                        }`}
                                    >
                                        {data.course.certificate ? (
                                            <MdCheckCircle />
                                        ) : (
                                            <MdSchool />
                                        )}
                                    </div>
                                )}
                                {data.course.lessons.length > 0 &&
                                    (data.course.test &&
                                    data.course.certificate ? (
                                        <React.Fragment>
                                            <p>
                                                {t('completedAt', {
                                                    ns: 'course',
                                                })
                                                    .replace(
                                                        '%d',
                                                        format(
                                                            parseISO(
                                                                data.course
                                                                    .certificate
                                                                    ?.earned
                                                            ),
                                                            'P',
                                                            {
                                                                locale: locale.dateFNSLocale,
                                                            }
                                                        )
                                                    )
                                                    .replace(
                                                        '%d',
                                                        format(
                                                            parseISO(
                                                                data.course
                                                                    .certificate
                                                                    ?.earned
                                                            ),
                                                            'p',
                                                            {
                                                                locale: locale.dateFNSLocale,
                                                            }
                                                        )
                                                    )}
                                            </p>
                                            <Link
                                                to={`/certificates/${courseID}`}
                                            >
                                                {t('viewCertificate', {
                                                    ns: 'course',
                                                })}
                                            </Link>
                                        </React.Fragment>
                                    ) : (
                                        <p>
                                            {t('test.message', {
                                                ns: 'course',
                                            })}{' '}
                                            <Link to={`${courseID}/test`}>
                                                {' '}
                                                {t('test.callToAction', {
                                                    ns: 'course',
                                                })}
                                            </Link>
                                        </p>
                                    ))}
                            </div>
                        </React.Fragment>
                    ) : null}
                </div>
            )}
        </PageWrapper>
    )
}

export default CoursePage
