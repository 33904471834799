import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageWrapper from '../components/PageWrapper'
import { useQuery } from '@apollo/client'
import { GET_CERTIFICATES, GetCertificatesResponse } from '../utils/api'
import { LoadingIndicator } from '@abb/abb-common-ux-react'
import ABBTitle from '../components/ABBTitle'
import SearchBar from '../components/SearchBar'
import CertificateBox from '../components/CertificateBox'
import { useHistory } from 'react-router-dom'

type Props = {}

let searchTimeout: any = null

export function CertificatesPage(props: Props) {
    const [t] = useTranslation(['common', 'certificate'])
    const history = useHistory()

    const [searchText, setSearchText] = useState<string>('')
    const [searching, setSearching] = useState<boolean>(false)

    const { data, loading, error } = useQuery<GetCertificatesResponse>(
        GET_CERTIFICATES,
        { fetchPolicy: 'no-cache' }
    )

    return (
        <PageWrapper
            title={`${t('certificatesPage.title', { ns: 'certificate' })} - ${t(
                'appName'
            )}`}
        >
            <div id="certificates-content">
                <div className="header">
                    <ABBTitle
                        className="header-title"
                        text={t('certificatesPage.title', {
                            ns: 'certificate',
                        })}
                    />
                    <SearchBar
                        onChange={(text) => {
                            if (searchTimeout) {
                                clearTimeout(searchTimeout)
                            }
                            const t = text.toLowerCase()

                            setSearching(true)
                            searchTimeout = setTimeout(() => {
                                setSearchText(t)
                                setSearching(false)
                            }, 700)
                        }}
                        className="certificates-search-bar"
                    />
                </div>
                <div className="certificates-list">
                    {error && <p>{error.message}</p>}
                    {loading ||
                        (searching && (
                            <div className="loading">
                                <LoadingIndicator
                                    type={'bar'}
                                    sizeClass={'large'}
                                    determinate={false}
                                />
                            </div>
                        ))}
                    {!searching &&
                        data &&
                        data.user.certificates &&
                        data.user.certificates.length === 0 && (
                            <div className="error">
                                <p>
                                    {t('certificatesPage.noCertificates', {
                                        ns: 'certificate',
                                    })}
                                </p>
                            </div>
                        )}
                    {!searching &&
                        data &&
                        data.user.certificates &&
                        data.user.certificates
                            .filter((c) =>
                                c.course.name.toLowerCase().includes(searchText)
                            )
                            .map((c) => (
                                <CertificateBox
                                    certificate={c}
                                    onClick={() =>
                                        history.push(
                                            `/certificates/${c.course.id}`
                                        )
                                    }
                                />
                            ))}
                </div>
            </div>
        </PageWrapper>
    )
}

export default CertificatesPage
