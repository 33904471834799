import { formatDuration, intervalToDuration } from 'date-fns'
import { getCurrentLocale } from './locale'

const padLeftZeros = (n: number) => {
    return ('00' + n).slice(-2)
}

export const formattedVideoDuration = (seconds: number) => {
    const duration = intervalToDuration({
        start: 0,
        end: seconds * 1000,
    })
    if (duration.days || duration.weeks || duration.months || duration.years) {
        console.warn('Duration is greater than 1 day.')
    }
    return `${duration.hours ? duration.hours + ':' : ''}${
        duration.minutes
            ? duration.hours
                ? padLeftZeros(duration.minutes)
                : duration.minutes
            : '00'
    }:${duration.seconds ? padLeftZeros(duration.seconds) : '00'}`
}

export const formattedDuration = (seconds: number) => {
    const duration = intervalToDuration({
        start: 0,
        end: seconds * 1000,
    })

    return formatDuration(duration, {
        locale: getCurrentLocale().dateFNSLocale,
    })
}
