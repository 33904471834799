import { Locale } from 'date-fns'

export type Permission = {
    id: string
    name: string
    description: string
}

export type UserGroup = {
    id: string
    name: string
    permissions: Permission[]
}

export type Bookmark = {
    id: string
    content: SearchResult
}

export type Certificate = {
    id: string
    score: string
    earned: string
    course: Course
}

export type User = {
    id: string
    name: string
    surname: string
    email: string
    bookmarks?: Bookmark[]
    certificates?: Certificate[]
    groups?: UserGroup[]
}

export type Tag = {
    id: string
    name: string
    usage: number
}

export type Teacher = {
    id: string
    name: string
    surname: string
}

export type Answer = {
    id: string
    text: string
    correct?: boolean
}

export type Question = {
    id: string
    text: string
    answers: Answer[]
}

export type Test = {
    id: string
    questions: Question[]
    minimumScore: number
}

export type File = {
    id: string
    author?: User
    created: string
    viewableBy?: UserGroup[]
    uuid: string
    name: string
}

export type Link = {
    id: string
    name: string
    url: string
}

export type Requirement = {
    id: string
    text: string
    description: string
}

export type Video = {
    id: string
    author: User
    created: string
    viewableBy?: UserGroup[]
    uuid: string
    thumbnailURL?: string
    formats: { id: string; extension: string }[]
    name: string
    durationSeconds: number
}

export type StrippedLesson = {
    id: string
    name: string
}

export type LessonParentCourse = {
    id: string
    name: string
    lessons: StrippedLesson[]
}

export type Lesson = {
    id: string
    author?: User
    teachers?: Teacher[]
    created: string
    name: string
    description?: string
    tags: Tag[]
    video?: Video
    started: boolean
    finished: boolean
    requirements?: Requirement[]
    usefulLinks?: Link[]
    downloads?: File[]
    requiredTimeSeconds: number
    isFavorite: boolean
    parent: LessonParentCourse
}
export type Course = {
    id: string
    author: User
    created: string
    name: string
    description?: string
    tags: Tag[]
    viewableBy?: UserGroup[]
    lessons: Lesson[]
    test?: Test
    // index of the first unwatched lesson, starting from 0
    progress: number
    isFavorite: boolean
    certificate?: Certificate
}

export type SearchResult = (Course | Lesson) & { __type: 'Course' | 'Lesson' }

export type Category = {
    title: string
    items: SearchResult[]
}

export type WillLocale = {
    name: string
    code: string
    icon?: any // TODO: replace with appropriate type
    dateFNSLocale: Locale
}

export enum ContentType {
    Course = 'contentType.course',
    Lesson = 'contentType.lesson',
}

export enum SortBy {
    Relevance = 'sortOrder.relevance',
    Name = 'sortOrder.name',
    Popularity = 'sortOrder.popularity',
}

export interface Content {
    id: string
    author?: User
    created: string
    name: string
    description?: string
    tags: Tag[]
    isFavorite: boolean
}

export function isCourse(item: Course | Lesson): item is Course {
    return (item as Course).lessons !== undefined
}

export function isLesson(item: Course | Lesson): item is Lesson {
    let l = item as Lesson
    return l.video !== undefined || l.parent !== undefined
}
