import React from 'react'

type Props = {
    text: string
    type?: 'primary' | 'secondary'
    className?: string
}

export function ABBTitle(props: Props) {
    switch (props.type || 'primary') {
        case 'primary': {
            return (
                <h1 className={`abb-title primary ${props.className}`}>
                    {props.text}
                </h1>
            )
        }
        case 'secondary': {
            return (
                <h2 className={`abb-title secondary ${props.className}`}>
                    {props.text}
                </h2>
            )
        }
    }
}

export default ABBTitle
