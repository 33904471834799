import React from 'react'

type Props = {
    maxValue: number
    value: number
    className?: string
}

export function ProgressBar(props: Props) {
    return (
        <div className={`progress-bar ${props.className || ''}`}>
            <div
                className="fill"
                style={{ width: (props.value / props.maxValue) * 100 + '%' }}
            />
        </div>
    )
}

export default ProgressBar
