import React from 'react'
import { Course, Lesson } from '../utils/types'
import PreviewBox from './PreviewBox/PreviewBox'
import { VIDEOS_BASE_URI } from '../utils/api'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { formattedDuration } from '../utils/date'
import { useAuthToken } from '../utils/auth'
import PreviewBoxLoading from './PreviewBox/PreviewBoxLoading'
import ProgressBar from './ProgressBar'
import { capitalizeFirstLetter } from '../utils/locale'

type Props = {
    item: Course | Lesson
    type: 'Course' | 'Lesson'
    className?: string
}

export function SearchResultItem(props: Props) {
    const [t] = useTranslation('common')
    const token = useAuthToken()
    const history = useHistory()

    if (token === null) {
        return <PreviewBoxLoading />
    }
    if (props.type === 'Course') {
        const course = props.item as Course
        return (
            <PreviewBox
                className={`search-result-item${
                    props.className ? ' ' + props.className : ''
                }`}
                previews={course.lessons
                    .map((l) => {
                        return {
                            url: l.video
                                ? `${VIDEOS_BASE_URI}${l.video.uuid}/thumb?size=160;90&token=${token}`
                                : '',
                            durationSeconds: l.video
                                ? l.video.durationSeconds
                                : 0,
                        }
                    })
                    .slice(0, 3)}
                onClick={() => history.push(`/courses/${course.id}`)}
                title={course.name}
                middleContent={
                    <div className="course-progress-container">
                        <p className="course-progress-text">
                            {`${course.progress} ${t('iOfN')} ${
                                course.lessons.length
                            } ${t('lessons')} ${t('completedFemale')}`}
                        </p>
                        <ProgressBar
                            className="course-progress-bar"
                            maxValue={course.lessons.length}
                            value={course.progress}
                        />
                    </div>
                }
                progress={course.progress}
                tags={course.tags}
                subtitle={[
                    `${course.lessons.length} ${
                        course.lessons.length === 1 ? t('lesson') : t('lessons')
                    }`,
                    `${t('timeRequired')}: ${
                        course.lessons.length > 0 &&
                        course.lessons[0].requiredTimeSeconds
                            ? formattedDuration(
                                  course.lessons.reduce(
                                      (tot, l) => tot + l.requiredTimeSeconds,
                                      0
                                  )
                              )
                            : t('unavailable')
                    }`,
                ]}
            />
        )
    } else if (props.type === 'Lesson') {
        const lesson = props.item as Lesson
        return (
            <PreviewBox
                onClick={() =>
                    history.push(
                        `/courses/${lesson.parent.id}/${
                            lesson.parent.lessons.findIndex(
                                (l) => l.id === lesson.id
                            ) + 1
                        }`
                    )
                }
                className={`search-result-item${
                    props.className ? ' ' + props.className : ''
                }`}
                previews={[
                    {
                        url: lesson.video
                            ? `${VIDEOS_BASE_URI}${lesson.video.uuid}/thumb?size=160;90&token=${token}`
                            : '',
                        durationSeconds: lesson.video?.durationSeconds || 0,
                    },
                ]}
                middleContent={
                    <div className="lesson-parent-container">
                        <p className="lesson-parent-text">
                            {capitalizeFirstLetter(t('partOf'))}:{' '}
                        </p>
                        <p>{lesson.parent.name}</p>
                    </div>
                }
                title={lesson.name}
                showDuration={true}
                tags={lesson.tags}
            />
        )
    } else {
        return <p>Whaaaat?</p>
    }
}

export default SearchResultItem
