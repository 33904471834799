import React from 'react'
import PageWrapper from '../components/PageWrapper'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { EXPLORE, ExploreResponse } from '../utils/api'
import ABBTitle from '../components/ABBTitle'
import { useAuthToken } from '../utils/auth'
import ExploreRow from '../components/ExploreRow'
import { LoadingIndicator } from '@abb/abb-common-ux-react'

export function Explore() {
    let [t] = useTranslation(['common', 'explore'])
    const token = useAuthToken()

    const { loading, error, data } = useQuery<ExploreResponse>(EXPLORE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    })

    console.log(data)

    return (
        <PageWrapper
            title={`${t('title', { ns: 'explore' })} - ${t('appName')}`}
        >
            <div id="explore-content">
                <ABBTitle
                    className="explore-title"
                    text={t('title', { ns: 'explore' })}
                />
                {loading && (
                    <LoadingIndicator
                        style={{ width: '100vw' }}
                        type={'bar'}
                        determinate={false}
                        color={'red'}
                        sizeClass={'large'}
                    />
                )}
                {error && <p>{error.message}</p>}
                <div className="explore-rows">
                    {data
                        ? data.explore.map((c) =>
                              c.items && c.items.length > 0 ? (
                                  <ExploreRow
                                      key={c.title}
                                      onLoad={() => console.log('loaded')}
                                      token={token || ''}
                                      categoryTitle={c.title}
                                      content={c.items}
                                  />
                              ) : undefined
                          )
                        : null}
                </div>
            </div>
        </PageWrapper>
    )
}

export default Explore
