import React from 'react'
import { Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { useTranslation } from 'react-i18next'
import { Button } from '@abb/abb-common-ux-react'
import { useHistory } from 'react-router-dom'

type Props = {
    score: number
    maxScore: number
    threshold: number
    courseID: string
}

export function Results(props: Props) {
    const [t] = useTranslation('test')
    const history = useHistory()
    const data01 = [
        { name: t('correctAnswers'), value: props.score, fill: '#007a33' },
        {
            name: t('wrongAnswers'),
            value: props.maxScore - props.score,
            fill: '#ff000f',
        },
    ]

    return (
        <div className="test-results">
            <p className="score-text">{`${t('yourScore')
                .replace('%d', props.score + '')
                .replace('%d', props.maxScore + '')}`}</p>
            <ResponsiveContainer
                className="score-chart-container"
                width="50%"
                height="50%"
            >
                <PieChart width={400} height={400}>
                    <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={data01}
                        cx="50%"
                        cy="50%"
                        outerRadius={70}
                        fill="#8884d8"
                        label
                    />
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
            <div className="calltoaction-container">
                {props.score >= props.threshold && (
                    <React.Fragment>
                        <p className="final-text">{t('pass')}</p>
                        <Button
                            text={t('viewCertificate')}
                            sizeClass={'large'}
                            type={'primary-red'}
                            shape={'pill'}
                            onClick={() => {
                                history.push(`/certificates/${props.courseID}`)
                            }}
                        />
                    </React.Fragment>
                )}
                {props.score < props.threshold && (
                    <React.Fragment>
                        <p className="final-text">{t('fail')}</p>
                        <Button
                            text={t('backToCourse')}
                            sizeClass={'large'}
                            shape="pill"
                            onClick={() => {
                                history.push(`/courses/${props.courseID}`)
                            }}
                        />
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

export default Results
