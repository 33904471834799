import React from 'react'
import { Answer as AnswerType } from '../../utils/types'

type Props = {
    answer: AnswerType
    selected?: boolean
    onClick?: (id: string) => void
    wrong?: boolean
    correct?: boolean
    disabled?: boolean
}

export function Answer(props: Props) {
    return (
        <div
            onClick={() => {
                if (props.onClick) props.onClick(props.answer.id)
            }}
            className={`answer-container ${
                props.selected && !props.correct && !props.wrong
                    ? 'selected'
                    : ''
            } ${props.wrong ? 'wrong' : ''} ${props.correct ? 'correct' : ''} ${
                props.disabled ? 'disabled' : ''
            }`}
        >
            <p className="answer">{props.answer.text}</p>
        </div>
    )
}

export default Answer
