import React from 'react'
import { useAuthToken } from '../utils/auth'
import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client'
import { API_URI } from '../utils/api'
import { setContext } from '@apollo/client/link/context'
import { LoadingIndicator } from '@abb/abb-common-ux-react'
import { getCurrentLocale } from '../utils/locale'

type Props = {
    children: React.ReactNode
}

export function ApolloWrapper(props: Props) {
    const token = useAuthToken()

    if (token === null) {
        return (
            <div className="unauth-container">
                <LoadingIndicator
                    type={'radial'}
                    determinate={false}
                    color={'red'}
                    sizeClass={'large'}
                />
            </div>
        )
    } else {
        console.log(token)
        const httpLink = createHttpLink({
            uri: API_URI,
        })

        const authLink = setContext((_, { headers }) => {
            // return the headers to the context so httpLink can read them
            return {
                headers: {
                    ...headers,
                    Authorization: token ? `Bearer ${token}` : '',
                    'Accept-Language': getCurrentLocale().code,
                },
            }
        })

        const client = new ApolloClient({
            connectToDevTools: true,
            link: authLink.concat(httpLink),
            cache: new InMemoryCache({
                possibleTypes: {
                    SearchResult: ['Course', 'Lesson'],
                },
            }),
        })

        return <ApolloProvider client={client}>{props.children}</ApolloProvider>
    }
}

export default ApolloWrapper
