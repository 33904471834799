import React from 'react'
import { Tag } from '../utils/types'
import { Link } from 'react-router-dom'

type Props = {
    tag: Tag
    className?: string
    highlight?: string
}

export function TagButton(props: Props) {
    let splitName = props.tag.name.split(
        new RegExp(`(${props.highlight})`, 'gi')
    )

    return (
        <Link
            to={encodeURI(`/search/%23${encodeURIComponent(props.tag.name)}`)}
            className={`tag-button ${props.className || ''}`}
        >
            <span className="tag-button-usage">{props.tag.usage}</span>#
            {!props.highlight && props.tag.name}
            {props.highlight &&
                splitName
                    .filter((s) => s.length > 0)
                    .map((s) =>
                        props.highlight &&
                        s
                            .toLowerCase()
                            .includes(props.highlight?.toLowerCase()) ? (
                            <span className="highlight">{s}</span>
                        ) : (
                            s
                        )
                    )}
        </Link>
    )
}

export default TagButton
