import React, { useState } from 'react'
import { MdClose, MdInfoOutline } from 'react-icons/md'

type Props = {
    className?: string
    text: string
    tooltip: string
}

export function TextWithTooltip(props: Props) {
    const [open, setOpen] = useState<boolean>(false)

    return (
        <div className="text-with-tooltip">
            <p>{props.text}</p>
            <MdInfoOutline
                onClick={() => {
                    setOpen(!open)
                }}
            />
            <div className={`tooltip ${!open ? 'hidden' : ''}`}>
                <p>{props.tooltip}</p>
                <MdClose onClick={() => setOpen(false)} />
            </div>
        </div>
    )
}

export default TextWithTooltip
