import React from 'react'
import { Certificate } from '../utils/types'
import { getCurrentLocale } from '../utils/locale'
import { format, parseISO } from 'date-fns'

type Props = {
    certificate: Certificate
    onClick?: () => void
}

export function CertificateBox(props: Props) {
    const locale = getCurrentLocale()

    return (
        <div
            className="certificate-box"
            onClick={() => {
                if (props.onClick) {
                    props.onClick()
                }
            }}
        >
            <span className="score">{props.certificate.score}</span>
            <p className="course-name">{props.certificate.course.name}</p>
            <p className="datetime">
                {format(parseISO(props.certificate.earned), 'Pp', {
                    locale: locale.dateFNSLocale,
                })}
            </p>
        </div>
    )
}

export default CertificateBox
