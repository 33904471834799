import React, { ReactElement, useState } from 'react'
import { formattedVideoDuration } from '../../utils/date'
import { Tag } from '../../utils/types'
import { MdChevronRight } from 'react-icons/md'
import { IconBaseProps } from 'react-icons'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from '../../utils/locale'

type Props = {
    previews: { url: string; durationSeconds: number }[]
    title: string
    subtitle?: string | string[] | ReactElement | ReactElement[]
    badge?: ReactElement<IconBaseProps>
    showDuration?: boolean
    showProgress?: boolean
    maxProgress?: number
    progress?: number
    tags: Tag[]
    onClick?: () => void
    className?: string
    middleContent?: ReactElement
}

export function PreviewBox(props: Props) {
    const [t] = useTranslation('common')
    const [imageLoadedCorrectly, setImageLoadedCorrectly] =
        useState<boolean>(true)

    return (
        <div
            className={`preview-box ${props.className || ''}`}
            onClick={() => {
                if (props.onClick) props.onClick()
            }}
            style={{
                paddingLeft: 50 - (props.previews.length || 1) * 10 + 'px',
            }}
        >
            <div
                className="preview-images-container"
                style={{
                    marginRight: (props.previews.length || 1) * 15 + 'px',
                }}
            >
                {props.previews.length === 0 && (
                    <img
                        src={`https://via.placeholder.com/160x90?text=${encodeURI(
                            t('errors.thumbnailUnavailable', { ns: 'common' })
                        )}`}
                        className="preview-image-primary"
                        alt={t('unavailable')}
                    />
                )}
                {props.previews.map(({ url, durationSeconds }, index) => {
                    if (index === 0) {
                        return (
                            <React.Fragment key={index}>
                                <img
                                    onError={() => {
                                        setImageLoadedCorrectly(false)
                                    }}
                                    style={
                                        props.previews.length > 1
                                            ? {
                                                  zIndex:
                                                      props.previews.length + 1,
                                              }
                                            : undefined
                                    }
                                    className="preview-image-primary"
                                    src={url}
                                    alt={t('unavailable')}
                                />
                                {props.showDuration &&
                                durationSeconds > 0 &&
                                imageLoadedCorrectly ? (
                                    <span
                                        className="duration"
                                        style={
                                            props.previews.length > 1
                                                ? {
                                                      zIndex:
                                                          props.previews
                                                              .length + 1,
                                                  }
                                                : undefined
                                        }
                                    >
                                        {formattedVideoDuration(
                                            durationSeconds
                                        )}
                                    </span>
                                ) : null}
                            </React.Fragment>
                        )
                    } else if (index < 3) {
                        return (
                            <img
                                key={index}
                                style={
                                    props.previews.length > 1
                                        ? {
                                              top: -index * 10 + 'px',
                                              left: index * 10 + 'px',
                                              zIndex:
                                                  props.previews.length -
                                                  index -
                                                  1,
                                          }
                                        : undefined
                                }
                                className="preview-image-secondary"
                                src={url}
                                alt={t('unavailable')}
                            />
                        )
                    } else {
                        return null
                    }
                })}
            </div>
            <div className="preview-box-title-container">
                <p className="preview-box-title">
                    {props.title} {props.badge}
                </p>
                {Array.isArray(props.subtitle) ? (
                    props.subtitle.map(
                        (s: string | ReactElement, idx: number) => (
                            <p key={idx} className="preview-box-subtitle">
                                {s}
                            </p>
                        )
                    )
                ) : (
                    <p className="preview-box-subtitle">{props.subtitle}</p>
                )}
            </div>
            {props.middleContent}
            <div className="preview-box-tags-container">
                <span>
                    {capitalizeFirstLetter(t('tags'))}
                    {': '}
                </span>
                <div className="preview-box-tags">
                    {props.tags.map((tag, index) => {
                        let end = ''
                        if (index < props.tags.length - 1) {
                            end = ', '
                        }
                        return (
                            <p key={tag.id} className="preview-box-tag">
                                #{tag.name}
                                {end}
                            </p>
                        )
                    })}
                </div>
            </div>
            <MdChevronRight className="preview-box-chevron" />
        </div>
    )
}

export default PreviewBox
