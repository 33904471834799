import {
    Answer,
    Category,
    Certificate,
    Content,
    ContentType,
    Course,
    Lesson,
    Tag,
    User,
} from './types'
import conf from './conf/current.json'
import { gql } from '@apollo/client/core'

export const BACKEND_URI = conf.backendURI
export const ENVIRONMENT = conf.environment
export const API_URI = `${BACKEND_URI}/graphql`
export const VIDEOS_BASE_URI = `${BACKEND_URI}/static/video/`
export const FILES_BASE_URI = `${BACKEND_URI}/static/file/`

export interface SearchInput {
    contentType?: ('COURSE' | 'LESSON')[]
    text: string
    take?: number
    skip?: number
    completed?: boolean
    sort?: 'NAME' | 'POPULARITY' | 'RELEVANCE'
}

export interface SearchResponse {
    search: {
        items: ((Course | Lesson) & { __typename: string })[]
        totalCount: number
    }
}

export const SEARCH = gql`
    query Search(
        $text: String!
        $contentType: [ContentType!]
        $take: Int
        $skip: Int
        $completed: Boolean
        $sort: SortType!
    ) {
        search(
            text: $text
            contentType: $contentType
            take: $take
            skip: $skip
            completed: $completed
            sort: $sort
        ) {
            totalCount
            items {
                id
                name
                tags {
                    id
                    name
                }

                ... on Lesson {
                    parent {
                        id
                        name
                        lessons {
                            id
                        }
                    }
                    video {
                        id
                        uuid
                        thumbnailURL
                        durationSeconds
                    }
                    requiredTimeSeconds
                }

                ... on Course {
                    lessons {
                        video {
                            id
                            uuid
                            durationSeconds
                        }
                        requiredTimeSeconds
                    }
                    progress
                }
                __typename
            }
        }
    }
`

export interface GetCourseInput {
    input: {
        id: string
    }
}

export interface GetCourseResponse {
    course: Course
}

export const GET_COURSE = gql`
    query GetCourse($input: GetCourseInput!) {
        course(input: $input) {
            id
            name
            description
            isFavorite
            lessons {
                id
                isFavorite
                name
                description
                requiredTimeSeconds
                started
                finished
                video {
                    id
                    uuid
                    thumbnailURL
                    durationSeconds
                    formats {
                        id
                        extension
                    }
                }
                tags {
                    id
                    name
                }
                requirements {
                    id
                    text
                    description
                }
                usefulLinks {
                    id
                    name
                    url
                }
                downloads {
                    id
                    uuid
                    name
                }
            }
            tags {
                id
                name
            }
            progress
            certificate {
                id
                earned
            }
            test {
                id
                questions {
                    id
                    text
                    answers {
                        id
                        text
                    }
                }
                minimumScore
            }
        }
    }
`

export interface GetTagsResponse {
    tags: Tag[]
}

export const GET_TAGS = gql`
    query GetTags {
        tags {
            id
            name
            usage
        }
    }
`

export interface GetUserResponse {
    user: User
}

export const GET_USER = gql`
    query GetUser {
        user {
            id
            name
            surname
            email
        }
    }
`

export interface GetCoursesResponse {
    courses: Course[]
}

export const GET_COURSES = gql`
    query GetCourses {
        courses {
            id
            name
            lessons {
                id
                video {
                    id
                    thumbnailURL
                }
            }
        }
    }
`

export interface ExploreResponse {
    explore: Category[]
}

export const EXPLORE = gql`
    query Explore {
        explore {
            title
            items {
                id
                __typename
                name
                description

                ... on Course {
                    lessons {
                        id
                        video {
                            thumbnailURL
                        }
                    }
                }

                ... on Lesson {
                    parent {
                        id
                        name
                        lessons {
                            id
                        }
                    }
                    video {
                        id
                        thumbnailURL
                    }
                }
            }
        }
    }
`

export interface BookmarkContentInput {
    contentId: string
    contentType: ContentType
}

export interface BookmarkContentResponse {
    bookmarkContent: Content
}

export const BOOKMARK_CONTENT = gql`
    mutation BookmarkContent($contentId: ID!, $contentType: ContentType!) {
        bookmarkContent(contentId: $contentId, contentType: $contentType) {
            id
            isFavorite
        }
    }
`

export interface UnbookmarkContentInput {
    contentId: string
    contentType: ContentType
}

export interface UnbookmarkContentResponse {
    unbookmarkContent: Content
}

export const UNBOOKMARK_CONTENT = gql`
    mutation UnbookmarkContent($contentId: ID!, $contentType: ContentType!) {
        unbookmarkContent(contentId: $contentId, contentType: $contentType) {
            id
            isFavorite
        }
    }
`

export interface StartLessonInput {
    lessonId: string
}

export interface StartLessonResponse {
    startLesson: {
        lessonID: string
        started: boolean
    }
}

export const START_LESSON = gql`
    mutation StartLesson($lessonId: ID!) {
        startLesson(lessonId: $lessonId) {
            lessonID
            started
        }
    }
`

export interface FinishLessonInput {
    lessonId: string
}

export interface FinishLessonResponse {
    finishLesson: {
        lessonID: string
        finished: boolean
    }
}

export const FINISH_LESSON = gql`
    mutation FinishLesson($lessonId: ID!) {
        finishLesson(lessonId: $lessonId) {
            lessonID
            finished
        }
    }
`

export interface GetBookmarksResponse {
    user: {
        bookmarks: ((Course | Lesson) & { __typename: string })[]
    }
}

export const GET_BOOKMARKS = gql`
    query GetBookmarks {
        user {
            id
            bookmarks {
                id
                name
                tags {
                    id
                    name
                }

                ... on Lesson {
                    parent {
                        id
                        name
                        lessons {
                            id
                        }
                    }
                    video {
                        id
                        uuid
                        thumbnailURL
                        durationSeconds
                    }
                    requiredTimeSeconds
                }

                ... on Course {
                    lessons {
                        video {
                            id
                            uuid
                            durationSeconds
                        }
                        requiredTimeSeconds
                    }
                    progress
                }
                __typename
            }
        }
    }
`

export interface SubmitAnswerInput {
    input: {
        answerId: string
    }
}

export interface SubmitAnswerResponse {
    submitAnswer: {
        testId: string
        questionId: string
        correctAnswer: Answer
        firstTime: boolean
    }
}

export const SUBMIT_ANSWER = gql`
    mutation SubmitAnswer($input: TestAnswerInput!) {
        submitAnswer(input: $input) {
            testId
            questionId
            correctAnswer {
                id
                text
            }
            firstTime
        }
    }
`

export interface GetCertificateInput {
    input: {
        courseId: string
    }
}

export interface GetCertificateResponse {
    certificate: Certificate
}

export const GET_CERTIFICATE = gql`
    query GetCertificate($input: GetCertificateInput!) {
        certificate(input: $input) {
            id
            score
            earned
            course {
                id
                name
            }
        }
    }
`

export interface GetCertificatesResponse {
    user: User
}

export const GET_CERTIFICATES = gql`
    query GetCertificates {
        user {
            id
            name
            surname
            email
            certificates {
                id
                score
                earned
                course {
                    id
                    name
                }
            }
        }
    }
`
