import React, { useState } from 'react'
import PageWrapper from '../components/PageWrapper'
import { useQuery } from '@apollo/client'
import { GET_TAGS, GetTagsResponse } from '../utils/api'
import { LoadingIndicator } from '@abb/abb-common-ux-react'
import SearchBar from '../components/SearchBar'
import ABBTitle from '../components/ABBTitle'
import { useTranslation } from 'react-i18next'
import TagButton from '../components/TagButton'

export function Tags() {
    const { loading, error, data } = useQuery<GetTagsResponse>(GET_TAGS, {
        notifyOnNetworkStatusChange: true,
    })
    let [t] = useTranslation(['common', 'tags'])

    const [searchText, setSearchText] = useState<string>('')

    return (
        <PageWrapper title={`${t('title', { ns: 'tags' })} - ${t('appName')}`}>
            <div id="tags-content">
                {loading && (
                    <LoadingIndicator
                        style={{ width: '100vw' }}
                        type={'bar'}
                        determinate={false}
                        color={'red'}
                        sizeClass={'large'}
                    />
                )}
                {error && <p>{error.message}</p>}
                {data?.tags && (
                    <React.Fragment>
                        <div className="header">
                            <ABBTitle
                                className="header-title"
                                text={t('title', { ns: 'tags' })}
                            />
                            <SearchBar
                                onChange={(text) => {
                                    let s = text
                                    if (text && text[0] === '#') {
                                        s = text.substr(1)
                                    }
                                    setSearchText(s)
                                }}
                                className="tags-search-bar"
                            />
                        </div>
                        <div className="tags-list">
                            {data.tags
                                .filter(
                                    (t) =>
                                        t.usage > 0 &&
                                        t.name
                                            .toLowerCase()
                                            .includes(searchText.toLowerCase())
                                )
                                .map((t) => (
                                    <TagButton
                                        tag={t}
                                        key={t.id}
                                        className="tags-list-button"
                                        highlight={searchText}
                                    />
                                ))}
                        </div>
                    </React.Fragment>
                )}
            </div>
        </PageWrapper>
    )
}

export default Tags
