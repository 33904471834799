import React from 'react'

export function PreviewBoxLoading() {
    return (
        <div className="preview-box">
            <p>Loading...</p>
        </div>
    )
}

export default PreviewBoxLoading
