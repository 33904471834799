import React, { useEffect, useState } from 'react'
import PageWrapper from '../components/PageWrapper'
import ABBTitle from '../components/ABBTitle'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import SearchBar from '../components/SearchBar'
import { ContentType, SortBy } from '../utils/types'
import { useTranslation } from 'react-i18next'
import {
    Checkbox,
    Dropdown,
    DropdownOption,
    LoadingIndicator,
} from '@abb/abb-common-ux-react'
import { useAuthToken } from '../utils/auth'
import { useQuery } from '@apollo/client'
import { SEARCH, SearchInput, SearchResponse } from '../utils/api'
import SearchResultItem from '../components/SearchResultItem'
import PageSwitcher from '../components/PageSwitcher'
import { capitalizeFirstLetter } from '../utils/locale'

export function SearchResults() {
    const { searchText } = useParams<{ searchText: string }>()
    const history = useHistory()
    const location = useLocation()

    const token = useAuthToken()

    let parsedParameters: {
        showTaken: boolean
        sortBy: 'Relevance' | 'Popularity' | 'Name'
        contentTypes: ('Course' | 'Lesson')[]
        page: number
    } = {
        showTaken: true,
        sortBy: 'Relevance',
        contentTypes: ['Course', 'Lesson'],
        page: 0,
    }

    let parameters = location.search
        .substr(1, location.search.length)
        .split('&')
        .map((q) => {
            return { name: q.split('=')[0], value: q.split('=')[1] }
        })
        .map((q) => {
            let p: { name: string; value: boolean | string } = q
            if (q.value === 'false') {
                p.value = false
            } else if (q.value === 'true') {
                p.value = true
            }
            return p
        })

    parameters.forEach((p) => {
        if (p.name === 'showTaken') {
            parsedParameters.showTaken = p.value as boolean
        }

        if (p.name === 'sortBy') {
            if (
                !(
                    p.value === 'Relevance' ||
                    p.value === 'Popularity' ||
                    p.value === 'Name'
                )
            ) {
                p.value = 'Relevance'
            }
            parsedParameters.sortBy = p.value as
                | 'Relevance'
                | 'Popularity'
                | 'Name'
        }

        if (p.name === 'contentTypes') {
            let v = p.value as string
            parsedParameters.contentTypes = v.split(',') as (
                | 'Course'
                | 'Lesson'
            )[]
        }

        if (p.name === 'page') {
            let v = parseInt(p.value as string)
            parsedParameters.page = v
        }
    })

    const [t] = useTranslation(['common', 'search'])

    const typesOptions = Object.keys(ContentType).map((key) => {
        return {
            value: key as 'Course' | 'Lesson',
            label: t(ContentType[key as keyof typeof ContentType], {
                ns: 'search',
            }),
        }
    })
    const sortByOptions = Object.keys(SortBy).map((key) => {
        return {
            value: key as SortBy,
            label: t(SortBy[key as keyof typeof SortBy], { ns: 'search' }),
        }
    })

    const [selectedContentTypes, setSelectedContentTypes] = useState<
        {
            value: string
            label: string
        }[]
    >(
        typesOptions.filter((t) =>
            parsedParameters.contentTypes.includes(t.value)
        )
    )
    const [showTaken, setShowTaken] = useState<boolean>(
        parsedParameters.showTaken || true
    )
    const [sortBy, setSortBy] = useState<{ value: SortBy; label: string }[]>(
        sortByOptions.filter(
            (s) => s.value === (parsedParameters.sortBy as SortBy)
        ) // TODO: ensure SortBy.Relevance is the default value
    )
    const [page, setPage] = useState<number>(parsedParameters.page)

    useEffect(() => {
        history.replace({
            search: `?showTaken=${showTaken}&sortBy=${
                sortBy[0].value
            }&contentTypes=${selectedContentTypes
                .map((ct) => ct.value)
                .join(',')}&page=${page}`,
        })
    }, [selectedContentTypes, sortBy, showTaken, searchText, page, history])

    const pageSize = 10

    const { loading, error, data } = useQuery<SearchResponse, SearchInput>(
        SEARCH,
        {
            variables: {
                text: decodeURIComponent(searchText),
                contentType: selectedContentTypes.map((ct) =>
                    ct.value.toUpperCase()
                ) as ('COURSE' | 'LESSON')[],
                skip: page * pageSize,
                take: pageSize,
                completed: showTaken,
                sort: sortBy[0].value.toUpperCase() as
                    | 'NAME'
                    | 'POPULARITY'
                    | 'RELEVANCE',
            },
            fetchPolicy: 'no-cache',
        }
    )

    console.log(data)
    console.log(error)
    console.log(loading)
    console.log(data && data?.search.items.length > 0)

    return (
        <PageWrapper
            title={`${t('title', {
                ns: 'search',
            })} "${decodeURIComponent(searchText)}" - ${t('appName')}`}
        >
            <div id="search-results-content">
                <ABBTitle
                    className="search-results-title"
                    text={`${t('title', {
                        ns: 'search',
                    })} "${decodeURIComponent(searchText)}"`}
                />
                <SearchBar
                    onSearch={(text) => {
                        text = text.trim()
                        if (text !== searchText) {
                            if (text.length > 0) {
                                history.push({
                                    pathname: encodeURI(
                                        `/search/${encodeURIComponent(
                                            decodeURIComponent(text)
                                        )}`
                                    ),
                                })
                            } else {
                                history.push('/')
                            }
                        }
                    }}
                    defaultValue={searchText}
                    className="results-search-bar"
                />
                <div className="search-filters-box">
                    <div className="filters-type-container">
                        <div className="filters-type">
                            <span className="filters-label">
                                {capitalizeFirstLetter(t('show'))}:
                            </span>
                            <Dropdown
                                className="search-filters-types"
                                multiselect
                                value={selectedContentTypes}
                                onChange={(types) => {
                                    setPage(0)
                                    setSelectedContentTypes(types)
                                }}
                            >
                                {typesOptions.map((to) => (
                                    <DropdownOption
                                        label={to.label}
                                        key={to.label}
                                        value={to.value}
                                    />
                                ))}
                            </Dropdown>
                        </div>
                        <Checkbox
                            value={showTaken}
                            onChange={() => {
                                setPage(0)
                                setShowTaken(!showTaken)
                            }}
                            label={t('showAlreadyTaken', { ns: 'search' })}
                        />
                    </div>
                    <div className="filters-sort-order-container">
                        <div className="filters-sort">
                            <span className="filters-label">
                                {capitalizeFirstLetter(t('sortBy'))}:
                            </span>
                            <Dropdown
                                className="search-filters-sort"
                                value={sortBy}
                                onChange={(sortBy) => {
                                    setPage(0)
                                    setSortBy(sortBy)
                                }}
                            >
                                {sortByOptions.map((sb) => (
                                    <DropdownOption
                                        label={sb.label}
                                        value={sb.value}
                                        key={sb.label}
                                    />
                                ))}
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {data && data.search && data.search.totalCount > 0 ? (
                    <p>
                        {data.search.totalCount}{' '}
                        {data.search.totalCount === 1
                            ? t('result', { ns: 'search' })
                            : t('results', { ns: 'search' })}
                    </p>
                ) : null}
                <div className="results">
                    {token === null ? (
                        <LoadingIndicator type="bar" determinate={false} />
                    ) : loading ? (
                        <LoadingIndicator type="bar" determinate={false} />
                    ) : error ? (
                        <p>{error.message}</p>
                    ) : data && data?.search.items.length > 0 ? (
                        data?.search.items.map((item) => (
                            <SearchResultItem
                                key={item.__typename + item.id}
                                item={item}
                                type={item.__typename as 'Course' | 'Lesson'}
                            />
                        ))
                    ) : (
                        <p className="no-results">
                            {t('noResults', { ns: 'search' })}
                        </p>
                    )}
                </div>
                <PageSwitcher
                    onChange={({ pageNumber }: { pageNumber: number }) => {
                        setPage(pageNumber)
                        window.scrollTo(0, 0)
                    }}
                    pageSize={pageSize}
                    totalCount={data?.search.totalCount || 0}
                    currentPage={page}
                />
            </div>
        </PageWrapper>
    )
}

export default SearchResults
