import React, { useState } from 'react'
import { LoadingIndicator } from '@abb/abb-common-ux-react'
import ABBTitle from '../components/ABBTitle'
import SearchBar from '../components/SearchBar'
import PageWrapper from '../components/PageWrapper'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GET_BOOKMARKS, GetBookmarksResponse } from '../utils/api'
import SearchResultItem from '../components/SearchResultItem'

const tagsRegex = /#([^ ]*)/g
const whitespaceRegex = /([ ]+)/g

let searchTimeout: any = null

export function Bookmarks() {
    const [searchText, setSearchText] = useState<string>('')
    const [searchTags, setSearchTags] = useState<string[]>([])
    const [searching, setSearching] = useState<boolean>(false)

    const [t] = useTranslation(['common', 'bookmarks'])

    const { data, loading, error } = useQuery<GetBookmarksResponse>(
        GET_BOOKMARKS,
        { fetchPolicy: 'no-cache' }
    )

    return (
        <PageWrapper
            title={`${t('title', { ns: 'bookmarks' })} - ${t('appName')}`}
        >
            <div id="bookmarks-content">
                {loading && (
                    <LoadingIndicator
                        style={{ width: '100vw' }}
                        type={'bar'}
                        determinate={false}
                        color={'red'}
                        sizeClass={'large'}
                    />
                )}
                <React.Fragment>
                    <div className="header">
                        <ABBTitle
                            className="header-title"
                            text={t('title', { ns: 'bookmarks' })}
                        />
                        <SearchBar
                            onChange={(text) => {
                                if (searchTimeout) {
                                    clearTimeout(searchTimeout)
                                }
                                const t = text.toLowerCase()
                                const tags = [...t.matchAll(tagsRegex)].map(
                                    (t) => t[1]
                                )

                                const textOnly = t
                                    .replace(tagsRegex, '')
                                    .replace(whitespaceRegex, ' ')
                                setSearching(true)

                                searchTimeout = setTimeout(() => {
                                    setSearchText(textOnly)
                                    setSearchTags(tags)
                                    setSearching(false)
                                }, 700)

                                console.log(textOnly)
                                console.log(tags)
                            }}
                            className="bookmarks-search-bar"
                        />
                    </div>
                    {error && <p>{error.message}</p>}
                    {data && (
                        <div className="bookmarks-list">
                            {searching && (
                                <LoadingIndicator
                                    determinate={false}
                                    type={'bar'}
                                />
                            )}
                            {!searching ? (
                                data.user.bookmarks &&
                                data.user.bookmarks.length > 0 ? (
                                    data.user.bookmarks
                                        .filter((b) => {
                                            return (
                                                (searchText === '' &&
                                                    searchTags.length === 0) ||
                                                ((b.name
                                                    .toLowerCase()
                                                    .includes(searchText) ||
                                                    b.description
                                                        ?.toLowerCase()
                                                        .includes(
                                                            searchText
                                                        )) &&
                                                    (searchTags.length === 0 ||
                                                        b.tags.some((t) =>
                                                            searchTags.includes(
                                                                t.name.toLowerCase()
                                                            )
                                                        )))
                                            )
                                        })
                                        .map((bookmark) => (
                                            <SearchResultItem
                                                className="bookmark-item"
                                                key={
                                                    bookmark.__typename +
                                                    bookmark.id
                                                }
                                                item={bookmark}
                                                type={
                                                    bookmark.__typename as
                                                        | 'Course'
                                                        | 'Lesson'
                                                }
                                            />
                                            //<p>{bookmark.name}</p>
                                        ))
                                ) : (
                                    <p className="no-results">
                                        {t('noBookmarks', { ns: 'bookmarks' })}
                                    </p>
                                )
                            ) : undefined}
                        </div>
                    )}
                </React.Fragment>
            </div>
        </PageWrapper>
    )
}

export default Bookmarks
