import React from 'react'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from '../utils/locale'

type Props = {
    pageSize: number
    totalCount: number
    onChange?: ({ pageNumber }: { pageNumber: number }) => void
    currentPage: number
}

export function PageSwitcher(props: Props) {
    const pageCount = Math.ceil(props.totalCount / props.pageSize)
    const [t] = useTranslation('common')

    const pageButtons: React.ReactElement[] = []
    let lastVisibleIndex = 0
    for (let pageIndex = 0; pageIndex < pageCount; pageIndex++) {
        if (
            pageCount > 5 &&
            pageIndex !== 0 &&
            pageIndex !== pageCount - 1 &&
            (pageIndex < props.currentPage - 1 ||
                pageIndex > props.currentPage + 1)
        ) {
            if (pageIndex === lastVisibleIndex + 1) {
                pageButtons.push(
                    <span key={pageIndex} className="page-switcher-button">
                        ...
                    </span>
                )
            }
        } else {
            pageButtons.push(
                <div
                    key={pageIndex}
                    onClick={() => {
                        if (props.onChange) {
                            props.onChange({
                                pageNumber: pageIndex,
                            })
                        }
                    }}
                    className={`page-switcher-button ${
                        props.currentPage !== pageIndex ? 'clickable' : ''
                    }`}
                >
                    {pageIndex + 1}
                </div>
            )
            lastVisibleIndex = pageIndex
        }
    }
    return (
        <div className="page-switcher">
            <span>{capitalizeFirstLetter(t('page'))}</span>
            <div className="page-switcher-button-row">{pageButtons}</div>
        </div>
    )
}

export default PageSwitcher
