import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/img/logo-small.png'
import TopBarButton from './TopBarButton'
import {
    MdCollectionsBookmark,
    MdExitToApp,
    MdPerson,
    MdSchool,
} from 'react-icons/md'
import TopBarProfileMenu from './TopBarProfileMenu'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from './LanguageSwitcher'
import { locales } from '../utils/locale'

type Props = {
    buttons?: {
        text: string
        urn: string
    }[]
    user?: {
        completeName: string
        email: string
    }
}

export function TopBar(props: Props) {
    const [t] = useTranslation('common')

    return (
        <nav id="top-bar">
            <Link to="/" id="logo">
                <img src={logo} alt="ABB Logo" />
            </Link>
            <LanguageSwitcher
                className="topbar-lang-switcher"
                locales={locales}
            />
            {props.buttons ? (
                <div className="topbar-button-container">
                    {props.buttons.map((button) => (
                        <TopBarButton
                            text={button.text}
                            urn={button.urn}
                            key={button.text + button.urn}
                        />
                    ))}
                </div>
            ) : null}
            {props.user ? (
                <TopBarProfileMenu
                    buttons={[
                        {
                            text: t('topBar.dropdown.profile'),
                            urn: '/profile',
                            icon: <MdPerson size={20} />,
                        },
                        {
                            text: t('topBar.dropdown.certificates'),
                            urn: '/certificates',
                            icon: <MdSchool size={20} />,
                        },
                        {
                            text: t('topBar.dropdown.bookmarks'),
                            urn: '/bookmarks',
                            icon: <MdCollectionsBookmark size={20} />,
                        },
                        {
                            text: t('topBar.dropdown.signOut'),
                            urn: '/logout',
                            icon: <MdExitToApp size={20} />,
                        },
                    ]}
                    user={props.user}
                />
            ) : null}
        </nav>
    )
}

export default TopBar
