import React from 'react'

type Props = {
    title: string
    elements: JSX.Element[]
    className?: string
}

export function BulletList(props: Props) {
    return (
        <div className={`bullet-list ${props.className || ''}`}>
            <h3 className="bullet-list-title">{props.title}</h3>
            <ul>
                {props.elements.map((element, index) => (
                    <li key={index}>{element}</li>
                ))}
            </ul>
        </div>
    )
}

export default BulletList
