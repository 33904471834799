import React, { useEffect, useState } from 'react'
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md'
import { useMutation } from '@apollo/client'
import {
    BOOKMARK_CONTENT,
    BookmarkContentInput,
    BookmarkContentResponse,
    UNBOOKMARK_CONTENT,
    UnbookmarkContentInput,
    UnbookmarkContentResponse,
} from '../utils/api'
import { ContentType } from '../utils/types'

type Props = {
    isFavorite: boolean
    className: string
    onClick?: () => void
    contentID: string
    contentType: 'LESSON' | 'COURSE'
}

export function FavoriteButton(props: Props) {
    const [hovered, setHovered] = useState<boolean>(false)

    const [isFavorite, setIsFavorite] = useState<boolean>(props.isFavorite)

    const [
        addToBookmarks,
        {
            data: addBookmarkData,
            loading: addBookmarkLoading,
            error: addBookmarkError,
        },
    ] = useMutation<BookmarkContentResponse, BookmarkContentInput>(
        BOOKMARK_CONTENT
    )
    const [
        removeFromBookmarks,
        {
            data: removeBookmarkData,
            loading: removeBookmarkLoading,
            error: removeBookmarkError,
        },
    ] = useMutation<UnbookmarkContentResponse, UnbookmarkContentInput>(
        UNBOOKMARK_CONTENT
    )

    useEffect(() => {
        if (addBookmarkData && !addBookmarkLoading && !addBookmarkError) {
            setIsFavorite(addBookmarkData.bookmarkContent.isFavorite)
        }

        if (addBookmarkError) {
            setIsFavorite(false)
        }
    }, [addBookmarkData, addBookmarkLoading, addBookmarkError])

    useEffect(() => {
        if (
            removeBookmarkData &&
            !removeBookmarkLoading &&
            !removeBookmarkError
        ) {
            setIsFavorite(removeBookmarkData.unbookmarkContent.isFavorite)
        }

        if (removeBookmarkError) {
            setIsFavorite(true)
        }
    }, [removeBookmarkData, removeBookmarkLoading, removeBookmarkError])

    const unbookmarkContent = async () => {
        setIsFavorite(false)
        try {
            await removeFromBookmarks({
                variables: {
                    contentId: props.contentID,
                    contentType: props.contentType as ContentType,
                },
            })
        } catch (e) {
            console.error(e)
        }
    }

    const bookmarkContent = async () => {
        setIsFavorite(true)
        try {
            await addToBookmarks({
                variables: {
                    contentId: props.contentID,
                    contentType: props.contentType as ContentType,
                },
            })
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <span
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className={props.className}
            onClick={async () => {
                if (isFavorite) {
                    await unbookmarkContent()
                } else {
                    await bookmarkContent()
                }
                if (props.onClick) {
                    props.onClick()
                }
            }}
        >
            {isFavorite ? (
                <MdFavorite className="favorite" size={20} />
            ) : hovered ? (
                <MdFavorite size={20} />
            ) : (
                <MdFavoriteBorder size={20} />
            )}
        </span>
    )
}

export default FavoriteButton
