import React, { useCallback, useEffect, useRef, useState } from 'react'
import PageWrapper from '../components/PageWrapper'
import ABBTitle from '../components/ABBTitle'
import BulletList from '../components/BulletList'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Link as ABBLink,
    LoadingIndicator,
} from '@abb/abb-common-ux-react'
import { MdFileDownload, MdOpenInNew } from 'react-icons/md'
import FavoriteButton from '../components/FavoriteButton'
import { Link, useHistory, useParams } from 'react-router-dom'
import PageNotFound from '../components/errors/PageNotFound'
import TagLink from '../components/TagLink'
import { capitalizeFirstLetter } from '../utils/locale'
import {
    FILES_BASE_URI,
    FINISH_LESSON,
    FinishLessonInput,
    FinishLessonResponse,
    GET_COURSE,
    GetCourseInput,
    GetCourseResponse,
    START_LESSON,
    StartLessonInput,
    StartLessonResponse,
    VIDEOS_BASE_URI,
} from '../utils/api'
import { useMutation, useQuery } from '@apollo/client'
import { useAuthToken } from '../utils/auth'
import TextWithTooltip from '../components/TextWithTooltip'
import LessonVideoPlayer from '../components/LessonVideoPlayer'

export function LessonPage() {
    const [t] = useTranslation(['common', 'lesson'])
    const history = useHistory()
    const token = useAuthToken()

    // Load lesson data

    const { courseID, lessonIndex } = useParams<{
        courseID: string
        lessonIndex: string
    }>()
    const lessonIndexNum = parseInt(lessonIndex)

    const { loading, error, data } = useQuery<
        GetCourseResponse,
        GetCourseInput
    >(GET_COURSE, {
        variables: { input: { id: courseID } },
        notifyOnNetworkStatusChange: true,
    })

    const lesson =
        data && data.course
            ? data?.course.lessons[lessonIndexNum - 1]
            : undefined

    // Send lesson start
    const sentLessonStart = useRef<boolean>(false)
    const sentLessonEnd = useRef<boolean>(false)

    const [startLesson] = useMutation<StartLessonResponse, StartLessonInput>(
        START_LESSON
    )

    const [finishLesson] = useMutation<FinishLessonResponse, FinishLessonInput>(
        FINISH_LESSON
    )

    const onVideoProgress = useCallback<
        ({
            playedPercentage,
            playedSeconds,
        }: {
            playedPercentage: number
            playedSeconds: number
        }) => void
    >(
        ({ playedPercentage, playedSeconds }) => {
            const onLessonEnd = async () => {
                if (lesson && !sentLessonEnd.current) {
                    console.log('Lesson complete!')
                    console.log(lesson)

                    try {
                        await finishLesson({
                            variables: {
                                lessonId: lesson.id,
                            },
                        })
                        sentLessonEnd.current = true
                    } catch (e) {
                        console.error(e)
                    }
                } else if (!lesson) {
                    console.error(
                        'Lesson is undefined... should never happen but... please report to the developers'
                    )
                }
            }

            const timeLeft = playedSeconds / playedPercentage - playedSeconds
            if (timeLeft < 120) {
                onLessonEnd()
            }
        },
        [finishLesson, lesson]
    )

    useEffect(() => {
        if (lesson) {
            const onLessonStart = async () => {
                if (lesson && !sentLessonStart.current) {
                    console.log('Lesson start!')
                    console.log(lesson)
                    try {
                        await startLesson({
                            variables: {
                                lessonId: lesson.id,
                            },
                        })
                        sentLessonStart.current = true
                    } catch (e) {
                        console.error(e)
                    }
                }
            }

            onLessonStart()
        }
    }, [lesson, startLesson])

    const resetProgress = () => {
        sentLessonStart.current = false
        sentLessonEnd.current = false
    }

    // Thumbnail
    const [thumbSize] = useState<{
        width: number
        height: number
    }>({ width: window.innerWidth / 2, height: (window.innerWidth * 9) / 32 })

    console.log(data?.course)

    // Component
    return (
        <PageWrapper
            key={window.location.pathname}
            title={`${lesson?.name || t('pageNotFound')} - ${
                (data && data.course && data?.course.name + ' - ') || ''
            }${t('appName')}`}
        >
            {loading || token === null ? (
                <LoadingIndicator
                    style={{ width: '100vw' }}
                    type={'bar'}
                    determinate={false}
                    color={'red'}
                    sizeClass={'large'}
                />
            ) : error ? (
                <p>{error.message}</p>
            ) : !data?.course || !lesson ? (
                <PageNotFound />
            ) : (
                <div id="lesson-content">
                    <div className="header">
                        <Link
                            className="header-course-link"
                            to={`/courses/${courseID}`}
                        >
                            <ABBTitle
                                className="header-title"
                                text={data?.course.name || ''}
                            />
                        </Link>
                        <div className="header-subtitle-container">
                            <h2 className="header-subtitle">{lesson.name}</h2>
                            <div className="header-info">
                                <div className="header-info-progress">
                                    <p>
                                        {`${capitalizeFirstLetter(
                                            t('lesson', {
                                                ns: 'common',
                                            })
                                        )} ${lessonIndex} ${t('iOfN', {
                                            ns: 'common',
                                        })} ${data?.course.lessons.length}`}
                                    </p>
                                    <FavoriteButton
                                        key={lesson.id}
                                        contentID={lesson.id}
                                        contentType={'LESSON'}
                                        isFavorite={lesson.isFavorite}
                                        className="lesson-favorite-button"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        {lesson.video && lesson.video.formats && (
                            <LessonVideoPlayer
                                key={window.location.pathname}
                                style={{
                                    maxWidth: '1280px',
                                    maxHeight: '720px',
                                }}
                                autoPlay={true}
                                controls={true}
                                width="50vw"
                                onProgress={onVideoProgress}
                                progressInterval={10000}
                                height="28.13vw"
                                preview={`${VIDEOS_BASE_URI}${lesson.video.thumbnailURL}?size=${thumbSize.width};${thumbSize.height}&token=${token}`}
                                sourceURLs={lesson.video.formats.map(
                                    (fmt) =>
                                        `${VIDEOS_BASE_URI}${lesson.video?.uuid}/${fmt.extension}?token=${token}`
                                )}
                            />
                        )}
                        <p className="description">{lesson.description}</p>
                        {lesson.requirements &&
                        lesson.requirements.length > 0 ? (
                            <BulletList
                                className="requirements-list"
                                title={t('requirements', { ns: 'lesson' })}
                                elements={lesson.requirements.map((r) => (
                                    <TextWithTooltip
                                        text={r.text}
                                        tooltip={r.description}
                                    />
                                ))}
                            />
                        ) : null}
                        {lesson.downloads && lesson.downloads.length > 0 ? (
                            <BulletList
                                className="downloads-list"
                                title={t('downloads', { ns: 'lesson' })}
                                elements={lesson.downloads.map((d) => (
                                    <ABBLink
                                        download={true}
                                        href={`${FILES_BASE_URI}${d.uuid}?token=${token}`}
                                    >
                                        {d.name} <MdFileDownload />
                                    </ABBLink>
                                ))}
                            />
                        ) : null}
                        {lesson.usefulLinks && lesson.usefulLinks.length > 0 ? (
                            <BulletList
                                className="links-list"
                                title={t('usefulLinks', { ns: 'lesson' })}
                                elements={lesson.usefulLinks.map((u) => (
                                    <ABBLink target="_blank" href={u.url}>
                                        {u.name} <MdOpenInNew />
                                    </ABBLink>
                                ))}
                            />
                        ) : null}
                        <div className="tags-container">
                            <span>
                                {capitalizeFirstLetter(
                                    t('tags', {
                                        ns: 'common',
                                    })
                                )}
                                {': '}
                            </span>
                            <div className="tags">
                                {lesson.tags.map((tag, index) => {
                                    let end = ''
                                    if (index < lesson.tags.length - 1) {
                                        end = ', '
                                    }
                                    return (
                                        <TagLink
                                            className="preview-box-tag"
                                            tag={tag}
                                            key={tag.id}
                                            end={end}
                                        />
                                    )
                                })}
                            </div>
                        </div>

                        <div className="progress-buttons">
                            <div
                                className={`progress-button-container ${
                                    lessonIndexNum > 1 ? '' : 'hidden'
                                }`}
                            >
                                <Button
                                    onClick={() => {
                                        history.push(
                                            `/courses/${courseID}/${
                                                lessonIndexNum - 1
                                            }`
                                        )
                                        resetProgress()
                                    }}
                                    className="progress-button progress-button-prev"
                                    text={t('buttons.previous', {
                                        ns: 'lesson',
                                    })}
                                    shape={'pill'}
                                />
                                <p>
                                    {
                                        data?.course.lessons[lessonIndexNum - 2]
                                            ?.name
                                    }
                                </p>

                                <img
                                    alt={t('unavailable')}
                                    className="next-previous-lesson-preview"
                                    src={
                                        data?.course.lessons[lessonIndexNum - 2]
                                            ?.video?.thumbnailURL
                                            ? `${VIDEOS_BASE_URI}${
                                                  data?.course.lessons[
                                                      lessonIndexNum - 2
                                                  ]?.video?.thumbnailURL || ''
                                              }?token=${token}&size=160;90`
                                            : ``
                                    }
                                />
                            </div>
                            <div
                                className={`progress-button-container ${
                                    data?.course.lessons.length &&
                                    lessonIndexNum < data?.course.lessons.length
                                        ? ''
                                        : 'hidden'
                                }`}
                            >
                                <Button
                                    onClick={() => {
                                        history.push(
                                            `/courses/${courseID}/${
                                                lessonIndexNum + 1
                                            }`
                                        )
                                        resetProgress()
                                    }}
                                    className="progress-button progress-button-next"
                                    type={'primary-red'}
                                    shape={'pill'}
                                    text={t('buttons.next', { ns: 'lesson' })}
                                />
                                <p>
                                    {data?.course.lessons[lessonIndexNum]?.name}
                                </p>

                                <img
                                    alt={t('unavailable')}
                                    className="next-previous-lesson-preview"
                                    src={
                                        data?.course.lessons[lessonIndexNum]
                                            ?.video?.thumbnailURL
                                            ? `${VIDEOS_BASE_URI}${
                                                  data?.course.lessons[
                                                      lessonIndexNum
                                                  ]?.video?.thumbnailURL || ''
                                              }?token=${token}&size=160;90`
                                            : ``
                                    }
                                />
                            </div>
                        </div>
                        {data?.course.lessons.length &&
                        lessonIndexNum >= data?.course.lessons.length ? (
                            <div className="final-lesson-call-to-action">
                                <h2>
                                    {t('finalLesson.congratulations', {
                                        ns: 'lesson',
                                    })}
                                </h2>
                                <p>
                                    {t('finalLesson.testDescription', {
                                        ns: 'lesson',
                                    })}
                                </p>
                                <Button
                                    onClick={() => history.push('test')}
                                    className="final-lesson-button"
                                    text={t('buttons.startTest', {
                                        ns: 'lesson',
                                    })}
                                    type={'primary-red'}
                                    shape={'pill'}
                                    sizeClass={'large'}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            )}
        </PageWrapper>
    )
}

export default LessonPage
