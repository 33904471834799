import React from 'react'
import PageWrapper from '../components/PageWrapper'
import { LoadingIndicator, Button } from '@abb/abb-common-ux-react'
import { useTranslation } from 'react-i18next'
import formaLogo from '../assets/img/forma-new-logo.svg'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import {
    GET_CERTIFICATE,
    GET_USER,
    GetCertificateInput,
    GetCertificateResponse,
    GetUserResponse,
} from '../utils/api'

export function CertificatePage() {
    let { courseID } = useParams<{ courseID: string }>()
    const [t] = useTranslation(['common', 'certificate'])

    const {
        data: userData,
        loading: loadingUserData,
        error: errorUserData,
    } = useQuery<GetUserResponse>(GET_USER)

    const { data, loading, error } = useQuery<
        GetCertificateResponse,
        GetCertificateInput
    >(GET_CERTIFICATE, { variables: { input: { courseId: courseID } } })

    return (
        <PageWrapper>
            <div id="certificate-content">
                <div className="completion-certificate-container">
                    <div id="completion-certificate">
                        {error && <p>{error.message}</p>}
                        {errorUserData && <p>{errorUserData.message}</p>}
                        {(loading || loadingUserData) && (
                            <LoadingIndicator
                                type={'radial'}
                                sizeClass={'large'}
                                determinate={false}
                            />
                        )}
                        {data && data.certificate && userData && (
                            <React.Fragment>
                                <img
                                    className="logo"
                                    src={formaLogo}
                                    alt={t('appName')}
                                />
                                <div className="content">
                                    <span className="flavor">
                                        {t('firstRow', { ns: 'certificate' })}
                                    </span>
                                    <span className="important">
                                        {`${userData.user.name} ${userData.user.surname}`}
                                    </span>
                                    <span className="flavor">
                                        {t('secondRow', { ns: 'certificate' })}
                                    </span>
                                    <span className="important">
                                        {data.certificate.course.name}
                                    </span>
                                    <span className="flavor">
                                        {' '}
                                        {t('thirdRow', { ns: 'certificate' })}
                                    </span>
                                    <span className="result">
                                        {data.certificate.score}
                                    </span>
                                </div>
                            </React.Fragment>
                        )}
                        {data && data.certificate === null && (
                            <p>{t('notCompleted', { ns: 'certificate' })}</p>
                        )}
                    </div>
                </div>
                {data && data.certificate && userData && (
                    <Button
                        onClick={() => window.print()}
                        sizeClass={'large'}
                        text={`${t('print')}`}
                    />
                )}
            </div>
        </PageWrapper>
    )
}

export default CertificatePage
