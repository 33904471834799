import React from 'react'
import PreviewBox from './PreviewBox/PreviewBox'
import { Lesson } from '../utils/types'
import { useHistory } from 'react-router-dom'
import { MdCheckCircle } from 'react-icons/all'
import { useAuthToken } from '../utils/auth'
import { VIDEOS_BASE_URI } from '../utils/api'
import PreviewBoxLoading from './PreviewBox/PreviewBoxLoading'

type Props = {
    courseID: string
    lessonIndex: number
    lesson: Lesson
}

export function CourseLessonPreview(props: Props) {
    const history = useHistory()
    const token = useAuthToken()

    if (token !== null) {
        return (
            <PreviewBox
                onClick={() =>
                    history.push(
                        `/courses/${props.courseID}/${props.lessonIndex}`
                    )
                }
                badge={
                    props.lesson.finished ? (
                        <MdCheckCircle title={'Lesson completed'} />
                    ) : undefined
                }
                previews={[
                    {
                        url:
                            `${VIDEOS_BASE_URI}${props.lesson.video?.thumbnailURL}?size=320;180&token=${token}` ||
                            'https://picsum.photos/160/90', // TODO: replace placeholder
                        durationSeconds:
                            props.lesson.video?.durationSeconds || 0,
                    },
                ]}
                title={props.lesson.name}
                showDuration={props.lesson.video !== undefined}
                tags={props.lesson.tags}
            />
        )
    } else {
        return <PreviewBoxLoading />
    }
}

export default CourseLessonPreview
