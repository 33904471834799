import React, { useEffect, useState } from 'react'
import PageWrapper from '../components/PageWrapper'
import ABBTitle from '../components/ABBTitle'
import { Button, LoadingIndicator } from '@abb/abb-common-ux-react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import {
    GET_COURSE,
    GetCourseInput,
    GetCourseResponse,
    SUBMIT_ANSWER,
    SubmitAnswerInput,
    SubmitAnswerResponse,
} from '../utils/api'
import { Question, TestResults } from '../components/Test'
import ProgressBar from '../components/ProgressBar'
import PageNotFound from '../components/errors/PageNotFound'

export function TestPage() {
    let { courseID } = useParams<{ courseID: string }>()
    const [t] = useTranslation(['test', 'common'])
    const history = useHistory()

    const { loading, error, data } = useQuery<
        GetCourseResponse,
        GetCourseInput
    >(GET_COURSE, {
        variables: { input: { id: courseID } },
        notifyOnNetworkStatusChange: true,
    })
    const test = data && data.course ? data.course.test : null

    const [submitAnswer, { data: dataSubmit }] = useMutation<
        SubmitAnswerResponse,
        SubmitAnswerInput
    >(SUBMIT_ANSWER)

    const [questionIndex, setQuestionIndex] = useState<number>(-1)
    const [answer, setAnswer] = useState<
        { id: string; text: string } | undefined
    >()
    const [checkingAnswer, setCheckingAnswer] = useState<boolean>(false)
    const [confirmed, setConfirmed] = useState<boolean>(false)
    const [correctAnswerId, setCorrectAnswerId] = useState<string | undefined>()
    const [score, setScore] = useState<number>(0)

    useEffect(() => {
        console.log(`New score: ${score}`)
    }, [score])

    const nextQuestion = async () => {
        setConfirmed(false)
        setAnswer(undefined)
        setCorrectAnswerId(undefined)
        setQuestionIndex(questionIndex + 1)
    }

    const checkAnswer = async () => {
        if (!answer) {
            return
        }

        setConfirmed(true)
        setCheckingAnswer(true)

        await submitAnswer({ variables: { input: { answerId: answer.id } } })
    }

    useEffect(() => {
        console.log('hey')
        if (
            dataSubmit &&
            dataSubmit.submitAnswer.correctAnswer &&
            answer &&
            checkingAnswer
        ) {
            console.log(dataSubmit)
            setCorrectAnswerId(dataSubmit.submitAnswer.correctAnswer.id)
            if (
                answer &&
                answer.id === dataSubmit.submitAnswer.correctAnswer.id
            ) {
                setScore((s) => s + 1)
            }
            setCheckingAnswer(false)
        }
    }, [dataSubmit, answer, checkingAnswer])

    return (
        <PageWrapper
            title={`${
                data?.course ? data.course.name : t('error', { ns: 'common' })
            } - ${t('title')} - ${t('appName', {
                ns: 'common',
            })}`}
        >
            <div id="test-content">
                {error && <p>{error.message}</p>}
                {loading && (
                    <LoadingIndicator
                        style={{ width: '100vw' }}
                        type={'bar'}
                        determinate={false}
                        color={'red'}
                        sizeClass={'large'}
                    />
                )}
                {data && data.course === null ? (
                    <PageNotFound />
                ) : (
                    data &&
                    test &&
                    !data.course.certificate && (
                        <React.Fragment>
                            <ABBTitle
                                text={`${
                                    data.course
                                        ? data.course.name
                                        : t('error', { ns: 'common' })
                                } - ${t('title')}`}
                            />
                            {questionIndex === -1 && (
                                <React.Fragment>
                                    <p className="disclaimer">
                                        {t('disclaimer')
                                            .replace(
                                                '%d',
                                                test.questions.length + ''
                                            )
                                            .replace(
                                                '%d',
                                                test.minimumScore + ''
                                            )}
                                    </p>
                                    <Button
                                        className={'start-test-button'}
                                        type={'primary-red'}
                                        text={t('startButton')}
                                        shape={'pill'}
                                        sizeClass={'large'}
                                        onClick={() => setQuestionIndex(0)}
                                    />
                                </React.Fragment>
                            )}
                            {questionIndex >= 0 &&
                                questionIndex < test.questions.length && (
                                    <React.Fragment>
                                        <p>
                                            {t('progressText')
                                                .replace(
                                                    '%d',
                                                    questionIndex + 1 + ''
                                                )
                                                .replace(
                                                    '%d',
                                                    test.questions.length + ''
                                                )}
                                        </p>
                                        <ProgressBar
                                            className="test-progress-bar"
                                            maxValue={test.questions.length}
                                            value={
                                                questionIndex +
                                                (answer &&
                                                confirmed &&
                                                !checkingAnswer
                                                    ? 1
                                                    : 0)
                                            }
                                        />
                                        <Question
                                            key={questionIndex}
                                            disableSelection={checkingAnswer}
                                            question={
                                                test.questions[questionIndex]
                                            }
                                            correctAnswerId={correctAnswerId}
                                            onSelect={(id) => {
                                                setAnswer(
                                                    test.questions[
                                                        questionIndex
                                                    ].answers.filter(
                                                        (a) => a.id === id
                                                    )[0]
                                                )
                                            }}
                                        />
                                        {checkingAnswer ? (
                                            <LoadingIndicator
                                                className={
                                                    'loading-correct-answer'
                                                }
                                                sizeClass={'small'}
                                                determinate={false}
                                                type={'radial'}
                                            />
                                        ) : (
                                            <Button
                                                className={
                                                    'confirm-answer-button'
                                                }
                                                type={'primary-red'}
                                                text={
                                                    answer &&
                                                    confirmed &&
                                                    !checkingAnswer
                                                        ? questionIndex ===
                                                          test.questions
                                                              .length -
                                                              1
                                                            ? t('showResults')
                                                            : t('nextQuestion')
                                                        : t('confirmButton')
                                                }
                                                sizeClass={'large'}
                                                disabled={
                                                    answer === undefined &&
                                                    !checkingAnswer
                                                }
                                                onClick={
                                                    answer && confirmed
                                                        ? nextQuestion
                                                        : checkAnswer
                                                }
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                            {questionIndex >= test.questions.length && (
                                <React.Fragment>
                                    <TestResults
                                        courseID={courseID}
                                        threshold={test.minimumScore}
                                        score={score}
                                        maxScore={test.questions.length}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )
                )}
                {data && !test && (
                    <div className="test-unavailable">
                        <p>{t('testUnavailable')}</p>{' '}
                        <Button
                            text={t('backToCourse')}
                            sizeClass={'large'}
                            shape="pill"
                            onClick={() => {
                                history.push(`/courses/${courseID}`)
                            }}
                        />
                    </div>
                )}
                {data && test && data.course.certificate && (
                    <div className="test-unavailable">
                        <p>{t('alreadyCompleted')}</p>{' '}
                        <Button
                            text={t('backToCourse')}
                            sizeClass={'large'}
                            shape="pill"
                            onClick={() => {
                                history.push(`/courses/${courseID}`)
                            }}
                        />
                    </div>
                )}
            </div>
        </PageWrapper>
    )
}

export default TestPage
