import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import common_en_us from './translations/en-US/common.json'
import lesson_en_us from './translations/en-US/lesson.json'
import course_en_us from './translations/en-US/course.json'
import search_en_us from './translations/en-US/search.json'
import tags_en_us from './translations/en-US/tags.json'
import profile_en_us from './translations/en-US/profile.json'
import explore_en_us from './translations/en-US/explore.json'
import bookmarks_en_us from './translations/en-US/bookmarks.json'
import test_en_us from './translations/en-US/test.json'
import certificate_en_us from './translations/en-US/certificate.json'

import common_it_IT from './translations/it-IT/common.json'
import lesson_it_IT from './translations/it-IT/lesson.json'
import course_it_IT from './translations/it-IT/course.json'
import search_it_IT from './translations/it-IT/search.json'
import tags_it_IT from './translations/it-IT/tags.json'
import profile_it_IT from './translations/it-IT/profile.json'
import explore_it_IT from './translations/it-IT/explore.json'
import bookmarks_it_IT from './translations/it-IT/bookmarks.json'
import test_it_IT from './translations/it-IT/test.json'
import certificate_it_IT from './translations/it-IT/certificate.json'

import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'

import '@abb/abb-common-ux-react/styles.css'
import { getStoredLocale } from './utils/localstorage'

i18next.init({
    interpolation: { escapeValue: false },
    lng: getStoredLocale()?.code,
    fallbackLng: 'en-US',
    resources: {
        'en-US': {
            common: common_en_us,
            lesson: lesson_en_us,
            course: course_en_us,
            search: search_en_us,
            tags: tags_en_us,
            profile: profile_en_us,
            explore: explore_en_us,
            bookmarks: bookmarks_en_us,
            test: test_en_us,
            certificate: certificate_en_us,
        },
        'it-IT': {
            common: common_it_IT,
            lesson: lesson_it_IT,
            course: course_it_IT,
            search: search_it_IT,
            tags: tags_it_IT,
            profile: profile_it_IT,
            explore: explore_it_IT,
            bookmarks: bookmarks_it_IT,
            test: test_it_IT,
            certificate: certificate_it_IT,
        },
    },
})

console.log(navigator.language)
// MSAL configuration
const configuration: Configuration = {
    auth: {
        clientId: '545a705b-7525-4574-962c-70254e74ac2b',
        authority:
            'https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd',
        redirectUri: '/',
        postLogoutRedirectUri: 'https://www.abb.com',
    },
}

const pca = new PublicClientApplication(configuration)

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={pca}>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
