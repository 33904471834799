import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
    text: string
    urn: string
}

export function TopBarButton(props: Props) {
    return (
        <Link className="topbar-button" to={props.urn}>
            {props.text}
        </Link>
    )
}

export default TopBarButton
