import React, { useState } from 'react'

import { Question as QuestionType } from '../../utils/types'
import Answer from './Answer'

type Props = {
    question: QuestionType
    onSelect?: (id: string) => void
    correctAnswerId?: string
    disableSelection?: boolean
}

export function Question(props: Props) {
    const [selectedAnswer, setSelectedAnswer] = useState<string | undefined>()

    return (
        <div className="question-container">
            <p className="question">{props.question.text}</p>
            {props.question.answers.map((a) => (
                <Answer
                    onClick={(id) => {
                        if (!props.correctAnswerId && !props.disableSelection) {
                            setSelectedAnswer(id)
                            if (props.onSelect) {
                                props.onSelect(id)
                            }
                        }
                    }}
                    wrong={
                        props.correctAnswerId !== undefined &&
                        a.id === selectedAnswer &&
                        a.id !== props.correctAnswerId
                    }
                    correct={
                        props.correctAnswerId !== undefined &&
                        a.id === props.correctAnswerId
                    }
                    disabled={
                        props.correctAnswerId !== undefined &&
                        a.id !== props.correctAnswerId &&
                        a.id !== selectedAnswer
                    }
                    answer={a}
                    key={a.id}
                    selected={a.id === selectedAnswer}
                />
            ))}
        </div>
    )
}

export default Question
