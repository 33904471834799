import React, { useState } from 'react'
import { MdAccountCircle } from 'react-icons/md'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { Link } from 'react-router-dom'

type Props = {
    buttons?: {
        text: string
        urn: string
        icon: any // TODO: actually use types
    }[]
    user: {
        completeName: string
        email: string
    }
}

export function TopBarProfileMenu(props: Props) {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
    const ref = useDetectClickOutside({
        onTriggered: () => {
            setDropdownOpen(false)
        },
    })

    return (
        <div
            className="user-profile-container"
            onClick={() => setDropdownOpen(!dropdownOpen)}
            ref={ref}
        >
            <span className="user-completename">{props.user.completeName}</span>
            <MdAccountCircle size={30} />
            <div
                ref={ref}
                onClick={(e) => e.stopPropagation()}
                className={'user-dropdown ' + (dropdownOpen ? 'visible' : '')}
            >
                {props.buttons
                    ? props.buttons.map((button, index) => (
                          <Link
                              className="user-dropdown-button"
                              to={button.urn}
                              key={index}
                          >
                              {button.text}
                              {React.cloneElement(button.icon, {
                                  className: `${
                                      button.icon.props.className
                                          ? button.icon.props.className
                                          : ''
                                  } user-dropdown-button-icon`,
                              })}
                          </Link>
                      ))
                    : null}
            </div>
        </div>
    )
}

export default TopBarProfileMenu
